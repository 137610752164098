export function latLngToCartesian(lat, lng, zoom) {
    const scale = Math.pow(2, zoom);
    const x = (128 + lng * 256/360)*scale;
    //let merc = Math.log(Math.tan((Math.PI/4)+(lat*Math.PI/360)))*scale;
    let sin = Math.sin(lat*Math.PI/180);
    let y = (128 + 0.5*Math.log((1+sin) / (1-sin)) *128/(Math.PI))*scale;

    return {x, y};
}

export function cartesianToLatLng(x, y, zoom) {
    const scale = Math.pow(2, zoom);
    const lng = (x/scale-128)*360/256.0;
    const e = (y-(256.0*Math.pow(2, zoom-1)))/(256.0*scale/(Math.PI*2));
    const lat = ((2*Math.atan(Math.exp(e)))-(Math.PI/2.0))/(Math.PI/180.0);

    return {lat, lng};
}

//Haversine Formula
export function latLngMeterDistance(lat1, lng1, lat2, lng2) {
    // Radius of Earth in Kilometers
    var R = 6378.137;
    var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
    var dLng = lng2 * Math.PI / 180 - lng1 * Math.PI / 180;
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
    Math.sin(dLng/2) * Math.sin(dLng/2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = R * c;
    return d * 1000;
}

// Returns the scaling of a value based on google maps zoom level
export function zoomScale(value, zoom) {
    return value * 10/Math.pow(2, (20-zoom));
}

// Returns the value in meters given a cartesian length and a zoom level
export function inverseZoomScale(value, zoom) {
    return value * Math.pow(2, (20-zoom))/10;
}

export function zoomToFit(width, height, target_width, target_height) {
    let zoomInverse = (value, target) => {
    return (20 - (Math.log(10*value/target)/Math.log(2)))
    }
    let hyp = Math.sqrt(Math.pow(width/2, 2) + Math.pow(height/2, 2));
    let width_zoom = zoomInverse(hyp*2, target_width);
    let height_zoom = zoomInverse(hyp*2, target_height);
    let result = Math.min(width_zoom, height_zoom);
    return result;
}
