import React, { Component } from 'react';
import '../common.css';
import './Sites.css';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Tooltip } from 'antd';
import {setRefreshInterval, clearRefreshInterval} from '../RefreshInterval';
var refreshInterval = null;
var token = sessionStorage.getItem("token");
var refreshAccessToken = function(value) {token = value};

var dup_site_id = "";
const database = sessionStorage.getItem("database");
const security = sessionStorage.getItem("security");
const user_id = sessionStorage.getItem("user_id");

class Sites extends Component {
    constructor(props) {
        super(props);
        this.state = {
            siteHeaderLabel: "Manage Sites",
            sites: [],
            site: {
                name: "",
            },
            siteName: "",
            siteId: "",
            currentSite: "",
            currentSiteName: "",
            floors: [],
            floorName: "",
            floorLevel: "",
            floorplan: "",
            currentFloor: "",
            currentFloorName: "",
            showFloors: false,
            showRooms: false,
            adding_site: false,
            editing_site: false,
            edit_changes_made: false,
            duplicating_site: false,
            orig_site: "",
        };
    
        this.addSite = this.addSite.bind(this);
        this.editSite = this.editSite.bind(this);
        this.viewEditSite = this.viewEditSite.bind(this);       
        this.viewAddSite = this.viewAddSite.bind(this);
        this.viewDupSite = this.viewDupSite.bind(this);
    }

    viewAddSite() {
        this.setState({
            adding_site: true,
        });
    }

    viewEditSite(site) {
        this.setState({
            adding_site: false,
            editing_site: true,
            edit_changes_made: false,
            siteName:site.name,
            siteId:site.id,
        });
    }

    viewDupSite() {
        if (window.confirm("Duplicating a site can take several minutes.  Continue?")) {
            this.dup_site_id = "";
            this.setState({
                duplicating_site: true,
            });
        }
    }

    addEditComplete = () => {
        this.setState({
            adding_site: false,
            editing_site: false,
            edit_changes_made: false,
            duplicating_site: false,
            siteName:"",
            siteId:"",
        });
    }

    componentDidMount() {
        this.getSites();
        setRefreshInterval(database, refreshInterval, refreshAccessToken);
    }

    componentWillUnmount() {
        clearRefreshInterval(refreshInterval);
    }

    getSites = () => {        
        if (security === "0") {
            fetch(`${database}/sites/?token=${token}`)
                .then(response => response.json())
                .then((sites) => {
                    let siteIDArray = [];
                    for (let i=0; i< sites.data.length; i++) {
                        siteIDArray.push(parseInt(sites.data[i].id));
                    }
                    let lastInsertID = Math.max(...siteIDArray);
                    this.dup_site_id = lastInsertID;
                    this.setState({ 
                        sites: sites.data,
                     });
                })
                .catch(err => console.error(err));        
        }
        else {
            console.log("user id::", user_id);
            fetch(`${database}/users/userId?token=${token}&user_id=${user_id}`)
            .then(response => response.json())
            .then(user => {
                let site_id = user.data[0].site_id;
                fetch(`${database}/sites/site?token=${token}&site_id=${site_id}`)
                .then(response => response.json())
                .then((sites) => {
                    let siteIDArray = [];
                    for (let i=0; i< sites.data.length; i++) {
                        siteIDArray.push(parseInt(sites.data[i].id));
                    }
                    let lastInsertID = Math.max(...siteIDArray);
                    this.dup_site_id = lastInsertID;
                    this.setState({ 
                        sites: sites.data,
                     });
                })
                .catch(err => console.error(err));
            })
            .catch(err => console.error(err)); 
        }   
    }

    getFloors = (site_id) => {                  
        console.log("site id", site_id);
        fetch(`${database}/floors/by_site?token=${token}&site_id=${site_id}`)
        .then( response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then( (floors) => {
            if (floors.data.length>0){
                console.log("site", site_id, "has", floors.data.length, "floors");                       
                let floors_array = [];
                for (let j=0; j<floors.data.length; j++) {
                    let floor = {
                        id:floors.data[j].id,
                        name:floors.data[j].name,
                        level:floors.data[j].level,
                        floorplan:floors.data[j].floorplan,
                        mime_type:floors.data[j].mime_type,
                        file_size:floors.data[j].file_size,
                        height:floors.data[j].height,
                        width:floors.data[j].width,
                    }
                    floors_array.push(floor);
                }
                console.log("floors array:", floors_array);
                this.setState({
                    floors:floors_array,
                });
                return floors_array;
            }
            else {
                console.log("site", site_id, "has no floors");
            }
        }).catch(function(err) {
            console.log(err);
        });      
    }

    addSite() {
        if (!this.state.siteName) {
            alert("Please enter a name for this site.");
            return;
        }
	    let promise_array = [];
        if (this.state.siteName !== ""){
            // console.log("adding site:", this.state.siteName);
            let data = {
                name: this.state.siteName,
            }
            // console.log("data, ", data);
            promise_array.push(fetch(`${database}/sites/add?token=${token}&name=${data.name}`)
            .then( response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            }).catch(err => console.error(err)));
            promise_array.push(this.setState({site: {name:""}, siteName:""}));
            promise_array.push(this.getSites());
        }
        this.addEditComplete();
	    return promise_array;
    }
    
    editSite() {
        if (!this.state.siteName) {
            alert("Please enter a name for this site.");
            return;
        }
        let data = {
            name: this.state.siteName,
            id: this.state.siteId,
        }
        // console.log("data:", data);
        fetch(`${database}/sites/edit?token=${token}&name=${data.name}&id=${data.id}`)
        .then( response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            
            return response.json();
        })
        .then(function(data) {
            // console.log(data)
            this.getSites();
        }).catch(function(err) {
            console.log(err);
        })
        this.getSites();
        this.addEditComplete();
    }

    duplicateSite = () => {
        this.setState({duplicating_in_progress:true});
        console.log("Duplicating site:", this.state.orig_site, "as", this.state.siteName);
        let promise_array = this.addSite();
        Promise.all(promise_array).then(() => {this.duplicateFloors()})
        this.addEditComplete();  
    }

    duplicateFloors = () => {
        let duplicate_floors = [];
        let original_floors = [];
        // promise arrays
        let readFloorPromisesOuter = [];
        let writeFloorPromises = [];
        let readFloorPromisesInner = [];
        let setFloorsState = [];
        let readZonePromises = [];
        let writeZonePromises = [];

        console.log("duplicating floors for site:", this.state.orig_site);

        // get all floors for this site
        readFloorPromisesOuter.push(fetch(`${database}/floors/by_site?token=${token}&site_id=${this.state.orig_site}`)
        .then( response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then( (floors) => {
            if (floors.data.length>0){
                let numFloors = parseInt(floors.data.length);
                console.log("site", this.state.orig_site, "has", floors.data.length, "floors");
                // for each floor returned
                for (let j=0; j<floors.data.length; j++) {
                    // build an array of original floor IDs
                    original_floors.push(floors.data[j].id);
                    // build an object with data for duplicated floor
                    let data = {
                        name:floors.data[j].name,
                        site: this.dup_site_id,
                        level:parseInt(floors.data[j].level),
                        floorplan:floors.data[j].floorplan,
                        mime_type:floors.data[j].mime_type,
                        file_size:floors.data[j].file_size,
                        height:floors.data[j].height,
                        width:floors.data[j].width,
                    }
                    // duplicate the original floor using new site id
                    writeFloorPromises.push(fetch(`${database}/floors/add?token=${token}&name=${data.name}&site_id=${data.site}&level=${data.level}&mime_type=${data.mime_type}&file_size=${data.file_size}&height=${data.height}&width=${data.width}`, {method:'POST', headers: {'Accept':'application/json', 'Content-Type': "application/json"}, body:JSON.stringify({image:data.floorplan})})
                    .then(() => {

                    })
                    .catch(err => console.error(err)));
                }
                
                // get id for floor just added
                readFloorPromisesInner.push(fetch(`${database}/floors/last_id?token=${token}&num=${numFloors}`)
                .then( response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then( (floors) => {                
                    for (let k=0; k<floors.data.length; k++ ) {
                        if (duplicate_floors.indexOf(floors.data[k].id) === -1) {
                            duplicate_floors.unshift(parseInt(floors.data[k].id))
                        }
                    }
                    setFloorsState.push(this.setState({
                        original_floors: original_floors,
                        duplicate_floors: duplicate_floors,
                    }));
                    // get zones for each original floor
                    for (let x=0; x<original_floors.length; x++) {
                        let duplicate_floor = duplicate_floors[x];
                        readZonePromises.push(fetch(`${database}/zones/by_floor?token=${token}&floor_id=${original_floors[x]}`)
                        .then( response => {
                            if (response.status >= 400) {
                                throw new Error("Bad response from server");
                            }
                            return response.json();
                        })
                        .then( (zones) => {
                            if (zones) {
                                // console.log("Zones::", zones);
                                // build duplicate data for each zone
                                for (let y=0; y<zones.data.length; y++) {
                                    let data = {
                                        name: zones.data[y].name,
                                        color: zones.data[y].color,
                                        safety_level: zones.data[y].safety_level,
                                        polygon: zones.data[y].polygon,
                                        site_id: this.dup_site_id,
                                        floor_id: duplicate_floor,
                                    }
                                    // add duplicated zone
                                    writeZonePromises.push(fetch(`${database}/zones/add?token=${token}&name=${data.name}&color=${data.color}&safety_level=${data.safety_level}&polygon=${data.polygon}&site_id=${data.site_id}&floor_id=${data.floor_id}`)
                                    .catch(err => console.log(err)));
                                }
                            }
                            else {
                                console.log("Original site (", this.state.orig_site, ") has no zones to duplicate")
                            }
                        })
                        .catch(function(err) {
                            console.log(err);
                        }));
                    }    
                })
                .catch(err => console.error(err)));

            }
            else {
                console.log("site", this.state.orig_site, "has no floors");
            }
            
        }).catch(function(err) {
            console.log(err);
        }));

        Promise.all(readFloorPromisesOuter).then(() => {
            let that = this;
            console.log("Outer Read Floor Promise");
            Promise.all(writeFloorPromises).then(() => {
                console.log("Write Floor Promise");
                Promise.all(readFloorPromisesInner).then(() => {
                    console.log("Inner Read Floor Promise");
                    Promise.all(setFloorsState).then(() => {
                        console.log("Set Floor State Promise");
                        Promise.all(readZonePromises).then(() => {
                            console.log("Read Zone Promise");
                            Promise.all(writeZonePromises).then(() => {
                                console.log("Write Zone Promise");
                                that.setState({duplicating_in_progress:false});
                                console.log("All promises complete.");
                            })
                        })
                    })
                })
            })
        })
    }

    logChangeName = (e) => {
        // console.log("event",e.target.name, e.target.value);
        const backslash = "\\";
        if (e.target.value.length>100){
            alert("Site Name cannot exceed 100 characters");
            return;
        }
        if (e.target.value.includes("#")||e.target.value.includes(backslash)||e.target.value.includes("+")||e.target.value.includes("'")) {
            alert("Site name cannot include these special characters: #, ', \\, +");
            e.target.value = e.target.value.replace("#", "");
            e.target.value = e.target.value.replace("'", "");
            e.target.value = e.target.value.replace("\\", "");
            e.target.value = e.target.value.replace("+", "");
        }
        this.setState({
            siteName: e.target.value,
            edit_changes_made: true,
        });  
    }
    
    logChangeSite = (site_id) => {
        // console.log("Site ID::", site_id);
        this.setState({orig_site: parseInt(site_id, 10)});  
    }

    render() {
        const {sites} = this.state;
        return (
            <div id="site-container">

                <Header/>

                <div className="page-title">{this.state.siteHeaderLabel}</div>
                
                {!this.state.adding_site&&!this.state.editing_site&&!this.state.duplicating_site&&
                <div id='sites-wrapper'>
                
                    <div className="top-button-row">
                        <div className="table-label">Site List</div>
                        <Tooltip placement="top" title="Duplicate Site">
                            <div className="dup-site-btn" onClick={this.viewDupSite} />
                        </Tooltip>
                        <Tooltip placement="top" title="Add Site">
                            <div className="add-site-btn" onClick={this.viewAddSite}/>
                        </Tooltip>
                    </div>

                    {this.state.sites && this.state.sites.length > 0 &&        
                    <table id="site-table">
                        <tbody>
                            {sites.map(site => 
                            <tr className="site-row" key={site.id}>
                                {site.name!=="All Sites"&&
                                <td className="main-col">{site.name}</td>
                                }
                                {site.name!=="All Sites"&&
                                <td className="action-wrapper action-wrapper-3" >

                                    <Tooltip placement="top" title="Edit Site">
                                        <div className="table-btn edit-btn" onClick={() => this.viewEditSite(site)} />
                                    </Tooltip>                                    

                                    <div className="manage-next-btn"
                                        onClick={()=>{
                                            sessionStorage.setItem("site_id", site.id);         
                                            sessionStorage.setItem("site_name", site.name);
                                            window.location.href="/floors";
                                        }}
                                    >FLOORS</div>
                                    
                                    <Tooltip placement="top" title="Delete Site">
                                        <div className="table-btn delete-btn"
                                            onClick={() => {
                                                if (window.confirm("Are you sure you want to delete this site? This will also remove its floors and zones.")){
                                                    fetch(`${database}/sites/delete?token=${token}&id=${site.id}`)
                                                    .then(this.getSites)
                                                    .catch(err => console.error(err))
                                                }
                                            }}
                                        />
                                    </Tooltip>

                                </td>
                                }
                            </tr>
                            )}
                        </tbody>
                    </table>
                    }
                </div> 
                } 

                {this.state.adding_site&&
                <div className="add-edit-wrapper">
                    <div className="add-edit-header">
                        <div className="add-edit-header-text">Add Site</div>
                        <div className="disabled-add-btn"/>
                    </div>
                    <div className="add-edit-form">
                        <div className="form-input-label">Name</div>
                        <input
                            className="form-control site-name" 
                            onChange={this.logChangeName} 
                            placeholder="Enter Site Name"
                            type='text'
                            name="name"
                            value={this.state.siteName}
                        />
                        <div className="cs-btn-group">
                            <button className="submit-btn cs-btn" onClick={this.addSite}>Submit</button>
                            <button className="cancel-btn cs-btn" onClick={this.addEditComplete}>Cancel</button>
                        </div>                            
                    </div>
                </div>                
                }

                {this.state.editing_site&&
                <div className="add-edit-wrapper">
                    <div className="add-edit-header">
                        <div className="add-edit-header-text">Edit Site</div>
                        <div className="disabled-edit-btn"/>
                    </div>
                    <div className="add-edit-form">
                    <div className="form-input-label">Name</div>
                        <input
                            className="form-control site-name" 
                            onChange={this.logChangeName} 
                            placeholder={this.state.siteName}
                            type='text'
                            name="name"
                            value={this.state.siteName}
                        />
                        <div className="cs-btn-group">
                            <button className="submit-btn cs-btn" onClick={this.editSite}>Submit</button>
                            <button className="cancel-btn cs-btn" onClick={this.addEditComplete}>Cancel</button>
                        </div>                            
                    </div>
                </div>                
                }

                {this.state.duplicating_site&&
                <div className="add-edit-wrapper">
                    <div className="add-edit-header">
                        <div className="add-edit-header-text">Duplicate Site</div>
                        <div className="disabled-dup-btn"/>
                    </div>
                    <div className="add-edit-form">
                        <div className="duplicate-site-selects">
                            <select id="duplicate-site-select" className="duplicate-site-select" onChange={(event) =>{this.logChangeSite(event.target.value)}} defaultValue={9999}>
                                <option value={9999} disabled>Select a Site to Duplicate</option>
                                {this.state.sites && this.state.sites.map((value, index) => {
                                    if (value.name !== "All Sites") {
                                        return (
                                            <option value={value.id}>{value.name}</option>
                                        )
                                    }
                                })}
                            </select>
                            <input
                                id="duplicate-name-input"
                                className="form-control site-name" 
                                onChange={this.logChangeName} 
                                placeholder="Enter a Name for the New Site"
                                type='text'
                                name="name"
                                value={this.state.siteName}
                            />
                        </div>
                        <div className="cs-btn-group">
                            <button className="submit-btn cs-btn" onClick={this.duplicateSite}>Submit</button>
                            <button className="cancel-btn cs-btn" onClick={this.addEditComplete}>Cancel</button>
                        </div>                            
                    </div>
                </div>                
                }

                <Footer/>  

            </div>
        );
    }
}

export default Sites;
