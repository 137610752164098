import React, { Component } from 'react';
import '../common.css';
import './Users.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Tooltip } from 'antd';
import {setRefreshInterval, clearRefreshInterval} from '../RefreshInterval';
var refreshInterval = null;
var token = sessionStorage.getItem("token");
var refreshAccessToken = function(value) {token = value};

const bcrypt = require('bcryptjs');
require('dotenv').config();
const salt = "$2a$12$nskGg4yxHrplv8cio.P24.";

const database = sessionStorage.getItem("database");

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            sites:[],
            roles:[],
            user: {
                name: "",
                role_id: "",
                username: "",
                password: "",
                site_id: "",
            },
            name: "",
            role_id: "",
            username: "",
            userPid: false,
            email: "",
            password: "",
            site_id: "",
            id: "",
            adding_user: false,
            editing_user: false,
            edit_changes_made: false,
            change_password: false,
            username_changed: false,
            userAlias_changed: false,
            UserPid_changed: false,
        };
    
        this.addUser = this.addUser.bind(this);
        this.editUser = this.editUser.bind(this);
        this.viewEditUser = this.viewEditUser.bind(this);       
        this.viewAddUser = this.viewAddUser.bind(this);
    }

    viewEditUser(user) {
        this.setState({
            adding_user: false,
            editing_user: true,
            edit_changes_made:false,
            change_password: false,
            username_changed: false,
            userAlias_changed: false,
            UserPid_changed: false,
            name:user.name,
            username:user.username,
            userAlias:user.userAlias,
            userPid: user.userPid,
            orig_password:user.password,
            password:user.password,
            password2:user.password,
            role_id:user.role_id,
            site_id:user.site_id,
            id:user.id,
        });
    }

    viewAddUser() {
        this.setState({
            adding_user: true,
        });
    }
    
    addEditComplete = () => {
        this.setState({
            editing_user: false, 
            edit_changes_made:false,
            change_password: false,
            username_changed: false,
            userAlias_changed: false,
            UserPid_changed: false,
            adding_user: false,
            name:"",
            username:"",
            userAlias:"",
            userPid:"",
            password:"",
            orig_password:"",
            password2:"",
            site_id:"",
            role_id:"",
            id:"",
        });
    }

    componentDidMount() {
        this.getSites();
        this.getRoles();
        this.getUsers();
        setRefreshInterval(database, refreshInterval, refreshAccessToken);
    }

    componentWillUnmount() {
        clearRefreshInterval(refreshInterval);
    }

    getUsers = () => {
        fetch(`${database}/users/?token=${token}`)
            .then(response => response.json())
            .then(users => this.setState({ users: users.data}))
            .then(console.log("getUsers:", this.state.users))
            .catch(err => console.error(err));        
    }
    
    getSites = () => {
        fetch(`${database}/sites/?token=${token}`)
            .then(response => response.json())
            .then(sites => this.setState({ sites: sites.data }))
            .then(console.log("getSites:", this.state.sites))
            .catch(err => console.error(err));        
    }    

    getRoles = () => {
        fetch(`${database}/users/roles/?token=${token}`)
            .then(response => response.json())
            .then(roles => this.setState({ roles: roles.data }))
            .then(console.log("Roles:", this.state.roles))
            .catch(err => console.error(err));        
    }
    
    addUser() {

        for (let i=0; i<this.state.users.length; i++) {
            if (this.state.users[i].username === this.state.username) {
                alert("This username is already in use. Please enter a unique username.");
                return;
            }
        }

        // if (!this.state.name) {
        //     alert("Please enter this user's name.");
        //     return;
        // }
        // else if (!this.state.username) {

        if (!this.state.userPid) {
            alert("Please enter this user's unique participant ID number.")
        }
        else if (!this.state.username) {
            alert("Please enter a unique username.  Username cannot contain spaces.");
            return;
        }
        else if (!this.state.password) {
            alert("Please enter password for this user.");
            return;
        }
        else if (!this.state.password2) {
            alert("Please re-enter password for this user.");
            return;
        }
        else if (!this.state.site_id) {
            alert("Please select a site for this user.");
            return;
        }
        else if (!this.state.role_id) {
            alert("Please select a role for this user.");
            return;
        }

        // default username if alias blank
        var user_alias = this.state.userAlias;
        if (!user_alias || user_alias==="") {
            console.log("DEFAULTING USERNAME");
            user_alias = this.state.username;
        }

        let data = {
            // user name disabled during study
            // name: this.state.name,
            name: "",
            userPid: this.state.userPid,
            role_id: this.state.role_id,
            site_id: this.state.site_id,
            username: this.state.username,
            userAlias: user_alias,
            email: this.state.email,
            password: this.state.password,
            password2: this.state.password2,
        }
        console.log("ADD USER DATA:", data);
        
        if (data.password === data.password2) {
            if (data.password.length >= 8 && data.password.length <= 20) {
                var containNum = data.password.match(/\d+/g);
                if (containNum != null) {
                    if (data.username.indexOf(' ') === -1) {
                        let hash = bcrypt.hashSync(data.password, salt);
                        // console.log("HASH:", hash);
                        // console.log("hash length:", hash.length);
                        fetch(`${database}/users/add?token=${token}&name=${data.name}&role_id=${data.role_id}&site_id=${data.site_id}&username=${data.username}&userAlias=${data.userAlias}&userPid=${data.userPid}&password=${hash}`)
                            .then(this.getUsers)
                            .catch(err => console.error(err));
                        this.setState({
                            user: {name:"", role_id:"", site_id:"", username:"", password:"", password2:"", onleave:0},
                            edit_changes_made:false,
                            change_password: false,
                            username_changed: false,
                            userAlias_changed: false,
                            UserPid_changed: false,
                        });
                        this.addEditComplete();                   
                    }
                    else {
                        alert("Username cannot contain spaces");
                        this.setState({username:""});
                        return;
                    }
                }
                else {
                    alert("Password must contain at least one number.");
                    this.setState({password:"", password2:""})
                    return;
                }
            }
            else {
                alert("Password length must be between 8 and 20.");
                this.setState({password:"", password2:""})
                return;
            }
        }
        else {
            alert("Passwords do not match.");
            this.setState({password:"", password2:""});
            return;
        }
    }

    editUser() {
        if (this.state.username_changed) {
            for (let i=0; i<this.state.users.length; i++) {
                if (this.state.users[i].username === this.state.username) {
                    alert("This username is already in use. Please enter a unique username.");
                    return;
                }
            }
        }

        // user name disabled during study
        // if (!this.state.name) {
        //     alert("Please enter this user's name.");
        //     return;
        // }

        if (!this.state.username) {
            alert("Please enter a unique username.  Username cannot contain spaces.");
            return;
        }
        else if (!this.state.site_id) {
            alert("Please select a site for this user.");
            return;
        }
        else if (!this.state.role_id) {
            alert("Please select a role for this user.");
            return;
        }

        // default username if alias blank
        var user_alias = this.state.userAlias;
        if (!user_alias || user_alias==="") {
            console.log("DEFAULTING USERNAME");
            user_alias = this.state.username;
        }        
        
        let data = {
            // user name disabled during study
            name: "",
            role_id: parseInt(this.state.role_id),
            username: this.state.username,
            userAlias: user_alias,
            userPid: this.state.userPid,
            password: this.state.password,
            password2: this.state.password2,
            site_id: parseInt(this.state.site_id),
            id: this.state.id,
        }
        console.log("orig pwd:", this.state.orig_password);
        console.log("data:", data);
        if (data.password !== this.state.orig_password) {
            if (data.password === data.password2) {
                if (data.password.length >= 8 && data.password.length <= 20) {
                    var containNum = data.password.match(/\d+/g);
                    if (containNum === null) {
                        alert("Password must contain at least one number.");
                        this.setState({password:"", password2:""})
                        return;
                    }
                }
                else {
                    alert("Password length must be between 8 and 20.");
                    this.setState({password:"", password2:""})
                    return;
                }
            }
            else {
                alert("Passwords do not match.");
                this.setState({password:"", password2:""})
                return;
            }

        }

        if (data.username.indexOf(' ') !== -1) {
            alert("Username cannot contain spaces");
            this.setState({username:""});
            return;
        }

        let hash = this.state.orig_password;
        if (this.state.password !== this.state.orig_password) {
            hash = bcrypt.hashSync(data.password, salt);
        }

        fetch(`${database}/users/edit?token=${token}&name=${data.name}&role_id=${data.role_id}&site_id=${data.site_id}&username=${data.username}&userAlias=${data.userAlias}&password=${hash}&id=${data.id}`)
        .then( response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then(function(data) {
            console.log(data);
            this.getUsers();
        }).catch(function(err) {
            console.log(err);
        });
        this.setState({
            user: {name:"",role_id:"", site_id:"", username:"", password:""},
            edit_changes_made: false,
            change_password: false,
            username_changed: false,
            userAlias_changed: false,
            UserPid_changed: false,
        });
        this.getUsers();
        this.addEditComplete(); 
    }

    logChangeName = (e) => {
        // console.log("event",e.target.name, e.target.value);
        const backslash = "\\";
        if (e.target.value.length>45){
            alert("User Name cannot exceed 45 characters");
            return;
        }
        if (e.target.value.includes("#")||e.target.value.includes(backslash)||e.target.value.includes("+")||e.target.value.includes("'")) {
            alert("User name cannot include these special characters: #, ', \\, +");
            e.target.value = e.target.value.replace("#", "");
            e.target.value = e.target.value.replace("'", "");
            e.target.value = e.target.value.replace("\\", "");
            e.target.value = e.target.value.replace("+", "");
        }
        this.setState({name: e.target.value});  
    }  

    logChangeUsername = (e) => {
        // console.log("event",e.target.name, e.target.value);
        const backslash = "\\";
        if (e.target.value.length>45){
            alert("Username cannot exceed 45 characters");
            return;
        }
        if (e.target.value.includes("#")||e.target.value.includes(backslash)||e.target.value.includes("+")||e.target.value.includes("'")) {
            alert("Username cannot include these special characters: #, ', \\, +");
            e.target.value = e.target.value.replace("#", "");
            e.target.value = e.target.value.replace("'", "");
            e.target.value = e.target.value.replace("\\", "");
            e.target.value = e.target.value.replace("+", "");
        }
        this.setState({
            username: e.target.value,
            edit_changes_made: true,
            username_changed: true,
        });  
    } 

    logChangeUserAlias = (e) => {
        const backslash = "\\";
        if (e.target.value.length>45){
            alert("User alias cannot exceed 45 characters");
            return;
        }
        if (e.target.value.includes("#")||e.target.value.includes(backslash)||e.target.value.includes("+")||e.target.value.includes("'")) {
            alert("User alias cannot include these special characters: #, ', \\, +");
            e.target.value = e.target.value.replace("#", "");
            e.target.value = e.target.value.replace("'", "");
            e.target.value = e.target.value.replace("\\", "");
            e.target.value = e.target.value.replace("+", "");
        }
        this.setState({
            userAlias: e.target.value,
            edit_changes_made: true,
            userAlias_changed: true,
        }); 
    }

    logChangeUserPid = (e) => {
        const backslash = "\\";
        if (e.target.value.length>45){
            alert("Personal identification number cannot exceed 45 characters");
            return;
        }
        if (e.target.value.includes("#")||e.target.value.includes(backslash)||e.target.value.includes("+")||e.target.value.includes("'")) {
            alert("Personal identification number cannot include these special characters: #, ', \\, +");
            e.target.value = e.target.value.replace("#", "");
            e.target.value = e.target.value.replace("'", "");
            e.target.value = e.target.value.replace("\\", "");
            e.target.value = e.target.value.replace("+", "");
        }
        this.setState({
            userPid: e.target.value,
            edit_changes_made: true,
            userPid_changed: true,
        }); 
    }

    logChangePassword = (e) => {
        const backslash = "\\";
        if (e.target.value.length>100){
            alert("Password cannot exceed 100 characters");
            return;
        }
        if (e.target.value.includes("#")||e.target.value.includes(backslash)||e.target.value.includes("+")||e.target.value.includes("'")) {
            alert("Password cannot include these special characters: #, ', \\, +");
            e.target.value = e.target.value.replace("#", "");
            e.target.value = e.target.value.replace("'", "");
            e.target.value = e.target.value.replace("\\", "");
            e.target.value = e.target.value.replace("+", "");
        }
        this.setState({
            password: e.target.value,
            edit_changes_made: true,
        });  
    }  

    logChangePassword2 = (e) => {
        const backslash = "\\";
        if (e.target.value.length>100){
            alert("Password cannot exceed 100 characters");
            return;
        }
        if (e.target.value.includes("#")||e.target.value.includes(backslash)||e.target.value.includes("+")||e.target.value.includes("'")) {
            alert("Password cannot include these special characters: #, ', \\, +");
            e.target.value = e.target.value.replace("#", "");
            e.target.value = e.target.value.replace("'", "");
            e.target.value = e.target.value.replace("\\", "");
            e.target.value = e.target.value.replace("+", "");
        }
        this.setState({
            password2: e.target.value,
            edit_changes_made: true,
        });  
    }  
    
    logSiteId = () => {
        // console.log("Site ID:",this.refs.siteSelect.value);
        this.setState({
            site_id: this.refs.siteSelect.value,
            edit_changes_made: true,
        });  
    }
    
    logRoleId = () => {
        // console.log("Role ID:",this.refs.roleSelect.value);
        this.setState({
            role_id: this.refs.roleSelect.value,
            edit_changes_made: true
        });  
    }

    togglePasswordBtn = () => {
        let toggle = !this.state.change_password;
        this.setState({change_password: toggle});
    }

    deleteUser = (user_id) => {

        console.log("user delete id::", user_id);

        fetch(`${database}/users/userId?token=${token}&user_id=${user_id}`)
        .then(response => response.json())
        .then(user => {
            console.log("user data::", user.data[0].role_id);
            let roles = this.state.roles;
            let user_role = "";
            for (let i=0; i< roles.length; i++) {
                if (roles[i].id === user.data[0].role_id) {
                    user_role = roles[i].role;
                }
            }
            if (user_role === "admin") {
                alert("System Administrator users may not be deleted. If you really need to remove this user, first change its role to something other than System Administrator.  CAUTION!: You should always have at least one System Adminstrator.  If you remove your last System Administrator then you will be unable to make any set up changes.");
                return;
            }

            if (window.confirm("Are you sure you want to delete this user?")) {
                fetch(`${database}/users/delete?token=${token}&id=${user_id}`)
                .then(this.getUsers)
                .catch(err => console.error(err))
            }
        })
        .catch(err => console.error(err)); 

    }

    render() {
        const {users, sites} = this.state;
        return (
            <div id="user-container">

                <Header/>

                <div className="page-title">Manage Users</div>

                {!this.state.adding_user&&!this.state.editing_user&&
                <div id="users-wrapper">
                    <div className="top-button-row">
                        <div className="table-label">User List</div>
                        <Tooltip placement="top" title="Add User">
                            <div className="add-btn" onClick={this.viewAddUser} />
                        </Tooltip>
                    </div>

                    <table id="user-table">
                        <tbody>
                            {this.state.users.map(User => 
                                <tr key={User.id} id="user-row">
                                    <td className="main-col">{User.userAlias}</td>
                                    <td className="action-wrapper user-col">
                                        <Tooltip placement="top" title="Edit User">
                                            <div className="table-btn edit-btn"
                                                onClick={() => this.viewEditUser(User)}
                                                style={{marginLeft:"auto"}}
                                            />                                            
                                        </Tooltip>

                                        <Tooltip placement="top" title="Delete User">
                                            <div className="table-btn delete-btn" 
                                                onClick={() => (this.deleteUser(User.id))}
                                            />
                                        </Tooltip>
                                    </td>

                                </tr>
                            )}
                        </tbody>
                    </table>

                </div>
                }

                {this.state.adding_user&&
                <div className="add-edit-wrapper">
                    <div className="add-edit-header">
                        <div className="add-edit-header-text">Add User</div>
                        <div className="disabled-add-btn"/>
                    </div>
                    <div className="add-edit-form">
                        <div className="user-input-label">Participant ID Number</div>
                        <input
                            className="form-control user-pid-input" 
                            onChange={this.logChangeUserPid} 
                            placeholder={"Enter Participant ID Number"}
                            type='text'
                            name="pid"
                            value={this.state.pid}
                        />
                        <div className="user-input-label">Alias</div>
                        <input
                            className="form-control user-alias-input" 
                            onChange={this.logChangeUserAlias} 
                            placeholder={"Enter user alias"}
                            type='text'
                            name="userAlias"
                            value={this.state.userAlias}
                        />
                        <div className="user-input-label">Username</div>
                        <input
                            className="form-control user-username-input" 
                            onChange={this.logChangeUsername} 
                            placeholder={"Enter username for login"}
                            type='text'
                            name="username"
                            value={this.state.username}
                        />
                        <div className="user-password-wrapper">
                            <div className="user-input-label">Password</div>
                            <div className="user-input-label">Re-enter Password</div>
                            <input
                                className="form-control user-password-input" 
                                onChange={this.logChangePassword} 
                                placeholder={"Enter password"}
                                type='password'
                                name="password"
                                value={this.state.password}
                            />  
                            <input
                                className="form-control user-password2-input" 
                                onChange={this.logChangePassword2} 
                                placeholder={"Re-enter password"}
                                type='password'
                                name="password2"
                                value={this.state.password2}
                            />    
                        </div>                        
                        <div className="user-input-label">Site</div>
                        <select 
                            className="user-site-select"
                            ref="siteSelect" 
                            name="site_select" 
                            id="site-select" 
                            onChange={(e) => {this.logSiteId()}} 
                            defaultValue={'DEFAULT'}
                        >
                            <option disabled value="DEFAULT">Select a Site</option>
                            {sites.map(Site => {
                                if (Site.name !== 'All Sites') {
                                    return <option key={Site.id} value={Site.id}>{Site.name}</option>
                                }
                            })}
                        </select>                                    
                        <div className="user-input-label">Role</div>
                        <select 
                            className="user-role-select"
                            ref="roleSelect" 
                            name="role_select" 
                            id="role-select" 
                            onChange={(e) => {this.logRoleId()}} 
                            defaultValue={'DEFAULT'}
                        >
                            <option disabled value="DEFAULT">Select a Role</option>
                            {this.state.roles.map(Role => 
                            <option key={Role.id} value={Role.id}>{Role.name}</option>
                            )}
                        </select>
                        <div className="cs-btn-group">
                            <button className="cs-btn submit-btn"onClick={this.addUser}>Submit</button>
                            <button className="cs-btn cancel-btn" onClick={this.addEditComplete}>Cancel</button>
                        </div>
                    </div>
                </div>
                }

                {this.state.editing_user&&
                <div className="add-edit-wrapper">
                    <div className="add-edit-header">
                        <div className="add-edit-header-text">Edit User</div>
                        <div className="disabled-edit-btn"/>
                    </div>
                    <div className="add-edit-form">
                    <div className="user-input-label">Participant ID Number can't be changed</div>
                        <input
                            className="form-control user-pid-input"  
                            placeholder={"Enter Participant ID Number"}
                            type='text'
                            name="pid"
                            value={this.state.pid}
                            disabled
                        />
                        <div className="user-input-label">Username can't be changed</div>
                        <input
                            className="form-control user-username-input" 
                            placeholder={"Enter username"}
                            type='text'
                            name="username"
                            value={this.state.username}
                            disabled
                        />
                        <div className="user-input-label">Alias</div>
                        <input
                            className="form-control user-alias-input" 
                            onChange={this.logChangeUserAlias} 
                            placeholder={"Enter user alias"}
                            type='text'
                            name="userAlias"
                            value={this.state.userAlias}
                        />
                        {this.state.change_password&&
                        <div className="user-password-wrapper">
                            <div className="user-input-label">Password</div>
                            <div className="user-input-label">Re-enter Password</div>
                            <input
                                className="form-control user-password-input" 
                                onChange={this.logChangePassword} 
                                placeholder={"Enter password"}
                                type='password'
                                name="password"
                            />  
                            <input
                                className="form-control user-password2-input" 
                                onChange={this.logChangePassword2} 
                                placeholder={"Re-enter password"}
                                type='password'
                                name="password2"
                            />    
                        </div>
                        }                        
                        <button className="password-toggle-btn" onClick={this.togglePasswordBtn}>{this.state.change_password?"Cancel Change Password":"Change Password"}</button>
                        <div className="user-input-label">Site</div>
                        <select 
                            className="user-site-select"
                            ref="siteSelect" 
                            name="site_select" 
                            id="site-select" 
                            onChange={(e) => {this.logSiteId()}} 
                            defaultValue={this.state.site_id}
                        >
                            <option disabled value="DEFAULT">Select a Site</option>
                            {sites.map(Site => {
                                if (Site.name !== 'All Sites') {
                                    return <option key={Site.id} value={Site.id}>{Site.name}</option>
                                }
                            })}
                        </select>
                        <div className="user-input-label">Role</div>                             
                        <select 
                            className="user-role-select"
                            ref="roleSelect" 
                            name="role_select" 
                            id="role-select" 
                            onChange={(e) => {this.logRoleId()}} 
                            defaultValue={this.state.role_id}
                        >
                            <option disabled value="DEFAULT">Select a Role</option>
                            {this.state.roles.map(Role => 
                            <option key={Role.id} value={Role.id}>{Role.name}</option>
                            )}
                        </select> 
                        <div className="cs-btn-group">
                            <button className="cs-btn submit-btn"onClick={this.editUser}>Submit</button>
                            <button className="cs-btn cancel-btn" onClick={this.addEditComplete}>Cancel</button>
                        </div>
                    </div>
                </div>
                }

                <Footer/>

            </div>
        );
    }
}

export default Users;
