/*
Use these functions on any page that accesses the database.  The JWT access
tokens for this project expire after 30 minutes after which a new access token 
is required.  The page can get a new access token using the refresh token
obtained in Login.js. 

Import these functions and variables with these lines:

    import {setRefreshInterval, clearRefreshInterval} from '../RefreshInterval';
    var refreshInterval = null;
    var token = sessionStorage.getItem("token");
    var refreshAccessToken = function(value) {token = value};

**NOTE, token must be a var.

Add setRefresInterval to componentDidMount() like this:

    setRefreshInterval(database, refreshInterval, refreshAccessToken);

Add clearRefreshInterval to componentWillUnmount() like this:

    clearRefreshInterval(refreshInterval);
*/

export function setRefreshInterval(database, refreshInterval, refreshAccessToken) {
    refreshInterval = setInterval(function(){
        const lastAccessTime = parseInt(sessionStorage.getItem("access_time"));
        const refreshToken = sessionStorage.getItem("refresh_token");
        if (Math.round((Date.now() - lastAccessTime)) / 60000 >= 30) {
            // console.log("fetching new access token");
	    if (window.confirm("Would you like to remain logged in?")) {
		fetch(`${database}/login/refresh/?token=${refreshToken}`)
		    .then(response => response.json())
		    .then((payload) => {
			refreshAccessToken(payload);
			const datetimeNow = new Date();
			const newAccessTime = datetimeNow.getTime();
			sessionStorage.setItem("token",payload);
			sessionStorage.setItem("access_time",newAccessTime);
		    })
		    .catch((err) => {
			console.error("Error refreshing access token:", err);
			return;
		    });
	    }
	    else {
		window.location.href = "/login"
	    }
        }
    }, 15000);
}

export function clearRefreshInterval(refreshInterval) {
    clearInterval(refreshInterval);
}
