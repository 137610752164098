import React, { Component } from 'react';
import '../common.css';
import './Preferences.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {setRefreshInterval, clearRefreshInterval} from '../RefreshInterval';
var refreshInterval = null;
var token = sessionStorage.getItem("token");
var refreshAccessToken = function(value) {token = value};

const database = sessionStorage.getItem("database");

class Preferences extends Component {

    constructor(props) {
        super(props);
        this.state = {
            preferences_visible: true,
            edit_preference_idx: null,
            edit_preference: null,
            edit_preference_value: null,
            display_time_visible: false,
            current_display_time: 10,
            edit_display_time: null,
            preferences: [],
        }    
    }

    componentDidMount = () => {
        this.getPreferences();
        setRefreshInterval(database, refreshInterval, refreshAccessToken);
    }

    componentWillUnmount() {
        clearRefreshInterval(refreshInterval);
    }

    getPreferences = () => {
        fetch(`${database}/preferences/?token=${token}`)
        .then(response => response.json())
        .then(preferences => this.setState({ preferences: preferences.data}, this.parsePreferences))
        .catch(err => console.error(err));  
        
    }

    parsePreferences = () => {
        console.log("parsePreferences()", this.state.preferences.length);
        const allPreferences = this.state.preferences;
        for (let i=0; i<allPreferences.length; i++) {
            console.log("PREFERENCE:", allPreferences[i].name);
            if (allPreferences[i].name==="lastLocationDisplayTime") {
                let current_display_time = parseInt(allPreferences[i].value, 10);
                let current_display_hours = parseInt(current_display_time/60, 10);
                if (current_display_hours===0) {
                    current_display_hours=null;
                }
                let current_display_minutes = current_display_time % 60;
                if (current_display_minutes===0) {
                    current_display_minutes=null;
                }
                let lastLocDisplayTimeIdx = allPreferences[i].id;
                this.setState({
                    current_display_time:current_display_time,
                    current_display_hours:current_display_hours,
                    current_display_minutes:current_display_minutes,
                    lastLocDisplayTimeIdx: lastLocDisplayTimeIdx,
                });
            }
        }
    }

    clearEditPreferences = () => {
        this.setState({
            edit_preference: null,
            preferences_visible: true,
            display_time_visible: false,
            edit_preference_idx: null,
        })
    }

    showEditDisplayTime = () => {
        this.setState({
            edit_preference: 'lastLocationDisplayTime',
            preferences_visible: false,
            display_time_visible: true,
            edit_preference_idx: this.state.lastLocDisplayTimeIdx,
            edit_preference_value: this.state.current_display_time,
        })
    }

    logLocationDisplayTime = () => {
        console.log("Edit Display Time:",this.refs.intervalSelect.value);
        this.setState({edit_preference_value:this.refs.intervalSelect.value});
    }

    editPreference = () => {
        let that = this;
        let preference = this.state.edit_preference;
        let id = this.state.edit_preference_idx;
        let value = parseInt(this.state.edit_preference_value, 10);
        console.log("EDIT PREFERENCE:", preference, value);
        if (preference&&value) {
            fetch(`${database}/preferences/edit?token=${token}&id=${id}&value=${value}`)
            .then( response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function(data) {
                console.log(data);
                that.clearEditPreferences();
            })
            .catch(function(err) {
                console.log(err);
                that.clearEditPreferences();
            });
            
        }
        else {
            alert("Please make a change before submitting.")
        }
        
        that.getPreferences();
    }

    render() {
        return (

            <div id="preferences-wrapper">

                <Header/>

                <div className="page-title">Preferences</div>

                <div id="preferences"
                    style={{
                        display:this.state.preferences_visible?"block":"none",
                    }}
                >

                    <div id="location-display-time-btn" 
                        className="preference-group"
                        onClick={this.showEditDisplayTime}
                    >                        
                        <div className="preference-label">Last location display time:</div>
                        <div className="preference-value">{this.state.current_display_hours}</div> 
                        {this.state.current_display_hours > 1?
                        <div className="preference-uom">hour(s) </div>
                        :
                        this.state.current_display_hours&&<div className="preference-uom">hour </div>
                        }

                        {this.state.current_display_minutes&&this.state.current_display_minutes>0 &&
                        <div className="preference-minutes"> {this.state.current_display_minutes} minutes</div>
                        }                
                    </div>

                </div>

                <div id="location-display-time"
                    className="setting-wrapper"
                    style={{
                        display:this.state.display_time_visible?"block":"none",
                    }}
                >
                    <div className="setting-header">Last Location Display Time</div>
                    <div className="setting-info">
                        Last location display time affects the length of time a patient's last location is displayed on the Live View map when Activlink cannot detect the tracking device on the network.  This situation might occur if the patient leaves the facility, the tracking device's battery is drained or the device is turned off.  After the chosen period of time elapses, the patient's last location will no longer be displayed on the map.  The default value is 1 hour.
                    </div>
                    <div className="setting-input-group">
                        <div className="setting-label">Last Location Display Time: </div>
                        <select 
                            ref="intervalSelect" 
                            name="interval_select" 
                            id="interval-select" 
                            onChange={(e) => {this.logLocationDisplayTime()}} 
                            defaultValue={this.state.current_display_time}
                            value={this.state.edit_preference_value}
                        >
                            <option disabled value="DEFAULT">Choose an Interval</option>
                            <option value={10}>10 Minutes</option>
                            <option value={15}>15 Minutes</option>
                            <option value={20}>20 Minutes</option>
                            <option value={30}>30 Minutes</option>
                            <option value={45}>45 Minutes</option>
                            <option value={60}>1 Hour</option>
                            <option value={75}>1 Hour 15 Minutes</option>
                            <option value={90}>1 Hour 30 Minutes</option>
                            <option value={105}>1 Hour 45 Minutes</option>
                            <option value={120}>2 Hours</option>
                            <option value={180}>3 Hours</option>
                            <option value={240}>4 Hours</option>
                            <option value={300}>5 Hours</option>
                            <option value={360}>6 Hours</option>
                            <option value={420}>7 Hours</option>
                            <option value={480}>8 Hours</option>                        
                        </select> 
                    </div>
                    <div className="setting-button-group">
                        <button 
                            className="setting-submit-button setting-button"
                            onClick={this.editPreference}
                        >Submit</button>                        
                        <button 
                            className="setting-cancel-button setting-button"
                            onClick={this.clearEditPreferences}
                        >Cancel</button>
                    </div>
                </div>

                <Footer/>
            
            </div>

        );
    }
}

export default Preferences;
