import React, { Component } from 'react';
import '../common.css';
import './Backup.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Modal from 'react-modal';
import {setRefreshInterval, clearRefreshInterval} from '../RefreshInterval';
var refreshInterval = null;
var token = sessionStorage.getItem("token");
var refreshAccessToken = function(value) {token = value};
const http = require('http');
const database = sessionStorage.getItem("database");

// boilerplate styling for react-modal
const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      marginTop: '10px',
      transform: 'translate(-50%, -50%)',
      width: '400px', 
      height: '262px',
      border: 'none',
      borderRadius: 'none',
    }
};

var sites_promise = [];
var patients_promise = [];

class Backup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: [],
            MAC_addresses: [],
            sites: [],
            patients: [],
            device: {
                name: "",
                MAC_address: "",
            },
            name: "",
            MAC_address: "",
            site_id: null,
            site_name: "",
            patient_id: null,
            patient_name: "",
            id: "",
            addModalIsOpen: false,
            editModalIsOpen: false,
            change_MAC: false,
	    loading:false,
        };
    
        this.downloadFile = this.downloadFile.bind(this);
        // this.editDevice = this.editDevice.bind(this);
        // this.openEditModal = this.openEditModal.bind(this);
        // this.afterOpenEditModal = this.afterOpenEditModal.bind(this);        
        // this.openAddModal = this.openAddModal.bind(this);
        // this.afterOpenAddModal = this.afterOpenAddModal.bind(this);
        // this.closeModal = this.closeModal.bind(this);
	this.fileImport = this.fileImport.bind(this);
	this.createBackup = this.createBackup.bind(this);
	this.deleteBackup = this.deleteBackup.bind(this);
	this.linkRef = React.createRef();
    }

    componentDidMount() {
        this.getBackups();
        setRefreshInterval(database, refreshInterval, refreshAccessToken);
    }

    componentWillUnmount() {
        clearRefreshInterval(refreshInterval);
    }

    openEditModal(device) {
        console.log("openEditModal(), device is:", device);
        this.setState({
            editModalIsOpen: true,
            name:device.name,
            MAC_address: device.MAC_address,
            site_id: device.site_id,
            site_name: device.site_name,
            patient_id: device.patient_id,
            patient_name: device.patient_name,
            id:device.id,
        });
        console.log("site:", device.site_id, device.site_name, "patient:", device.patient_id, device.patient_name);
    }
    
    afterOpenEditModal() {
    // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }
    
    openAddModal() {
        this.setState({
            addModalIsOpen: true,
        });
    }
    
    afterOpenAddModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }
    
    closeModal() {
        this.setState({
            editModalIsOpen: false, 
            addModalIsOpen: false,
            device: {name:"", MAC_address:""},
            name: "",
            MAC_address:"", 
            site_id:null,
            patient_id:null, 
            patient_name:"",
            site_name:"",
            change_MAC: false,
        });
    }

    getBackups = () => {
	console.log("LOAD")
            fetch(`${database}/backup/?token=${token}`)
                .then(response => response.json())
            .then((result) => {console.log("Result",JSON.stringify(result));this.setState({data:result})})
                .then(console.log("getDevices:"))
                .catch(err => console.error(err));
    }


    downloadFile(filename) {
	let that = this;
	//http.get({path:`/backup/download?file_name=${filename}`, hostname:'activlink.local', port:5000}, function(resp) {})
        fetch(`${database}/backup/download?token=${token}&file_name=${filename}`)
            .then((res) => {
		return res.blob();
	    })
	    .then(blob => {
		const href = window.URL.createObjectURL(blob);
		const a = that.linkRef.current;
		a.download = filename;
		a.href = href;
		a.click();
		a.href = '';
	    })
            .catch(err => console.error(err));
        this.closeModal();
    }


    logChangeName = (e) => {
        console.log("event",e.target.name, e.target.value);
        this.setState({name: e.target.value});  
    }

    logChangeMAC = (e) => {
        console.log("event",e.target.name, e.target.value);
        this.setState({
            MAC_address: e.target.value,
            change_MAC: true,
        });  
    }

    logSiteId = () => {
        console.log(this.refs.siteSelect.value);
        this.setState({site_id: this.refs.siteSelect.value});  
    }

    logPatientId = () => {
        console.log("New patient id:", this.refs.patientSelect.value);
        this.setState({patient_id: this.refs.patientSelect.value});  
    }

    fileImport(event) {
	//	console.log("Test")
	event.preventDefault();
	let data = new FormData();
	const file = this.file_ref.files[0];
	data.append('file', file);
	fetch(`${database}/backup/upload/?token=${token}`, {
	    method:'POST',
	    body:data,
	}).then(this.getBackups)
    }

    createBackup() {
	fetch(`${database}/backup/create_backup/token=${token}`).then(this.getBackups)
    }

    deleteBackup(file_name) {
	if (window.confirm("Are you sure you want to delete " + file_name + "?")) {
	fetch(`${database}/backup/delete/?token=${token}`, {
	    method:'POST',
	    headers: {"Content-Type":"application/json"},
	    body:JSON.stringify({filename:file_name}),
	}).then(this.getBackups)
	}
    }

    loadBackup(file_name) {
	if (window.confirm("Are you sure you want to load " + file_name + "?")) {
	    this.setState({loading:true});
	    fetch(`${database}/backup/load_backup/?token=${token}`, {
		method:'POST',
		headers: {"Content-Type":"application/json"},
		body:JSON.stringify({filename:file_name}),
	    }).then(() => {this.setState({loading:false})})
	}
    }

    render() {
	let data = this.state.data
        return (
            <div id="backup-wrapper">

                <Header/>

                <div className="page-title">Manage Backup</div>
		<p>{this.state.loading?"Loading":""}</p>
		<a ref={this.linkRef}/>
		<button onClick={this.createBackup} id="create-backup-btn" class="setup-add-btn"> Create Backup </button>
		<form inline onSubmit={this.fileImport}>
		<input type="file" accept=".sql" id="import-backup-btn" class="setup-add-btn" ref={(ref) => {this.file_ref = ref;}}/>
		<button id='import-backup-btn' class="setup-add-btn" type="submit">Upload</button>
		</form>
		<table id="backup-table">
		  <tr id="backup-table-header">
		    <th>Filename</th>
		    <th>Actions</th>
		  </tr>
		{data?
		 data.data.map((value, key) => {
		     return (
			 <tr id="backup-row">
			     <td>{value}</td>
			     <td>
			       <a class="table-btn" onClick={() => {this.downloadFile(value)}}>Export|</a>
			       <a class="table-btn" onClick={() => {this.loadBackup(value)}}>Load|</a>
			       <a class="table-btn" onClick={() => {this.deleteBackup(value)}}>Delete</a>
			     </td>
			 </tr>
		     )
		 })
		 :
		 ""
		}
	        </table>
            </div>
        );
    }
}

export default Backup;
