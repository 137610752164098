import React, { Component } from 'react';
import '../common.css';
import './Calibrations.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import gray_block from '../../images/gray_block.png';
import { zoomScale, zoomToFit, inverseZoomScale} from './utils.js';
import {setRefreshInterval, clearRefreshInterval} from '../RefreshInterval';
var refreshInterval = null;
var token = sessionStorage.getItem("token");
var refreshAccessToken = function(value) {token = value};

var MAP_WIDTH = 320;
var MAP_HEIGHT = 240;
if (window.screen.width <= 420) {
    MAP_WIDTH = window.screen.width*.64;
    MAP_HEIGHT = window.screen.width*.48;
}
var MIN_ZOOM = 15;
var MAX_ZOOM = 23;
// let aspect_ratio = 1.33;
const site_id = sessionStorage.getItem("site_id");
// const site_name = sessionStorage.getItem("site_name");
const floor_id = sessionStorage.getItem("floor_id");
// const floor_name = sessionStorage.getItem("floor_name");
const database = sessionStorage.getItem("database");
const security = sessionStorage.getItem("security");
const user_id = sessionStorage.getItem("user_id");

const pageTitle = "Calibrate Zones";

let image_src = "";
// let zone_id;

// Interval function handle
var interval_handle = null;

// Timestamp used for throttling scroll events
var scroll_throttle = new Date();

class Calibrations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            calibrating: false,
            calibrating_msg: "Begin Calibration",
            tracker:null,
            buildings:null,
            building_idx:null,
            floors:null,
            floor_idx: "DEFAULT",
            zones:[],
            map_img:gray_block,
            zoom:1,
            width:0,
            height:0,
            direction:0,
            cam_x:0,
            cam_y:0,
            panning:false,
            placing:false,
            moving_point:false,
            pan_start_x:0,
            pan_start_y:0,
            zone_name_input:"",
            zone_color_input:"red",
            zone_point_selected:-1,
            zone_selected:-1,
            zone_selected_id:"DEFAULT",
            devices:[],
            selected_device_MAC:"",
            selectDeviceModalIsOpen: false,
            zone_calibration_begun: false,
            record_count: 0,
            record_count_message: "Calibration beginning - please wait.",
            device_connected: true,
            connection_timeout:false,
            ellipses: "",
        };
    }

    componentDidMount() {
        // this.getFloorplan();
        window.addEventListener('keydown', this.deletePoint);
        this.getSites(); 
        this.getZones();
        // this.getDevices();
        setRefreshInterval(database, refreshInterval, refreshAccessToken);
    }

    componentWillUnmount() {
        // console.log("UNMOUNT")
        window.removeEventListener('keydown', this.deletePoint);
        localStorage.setItem('zones', JSON.stringify(this.state.zones));
        clearRefreshInterval(refreshInterval);
    }

    // MOUSE EVENT HANDLERS
    mapMouseDown = (event) => {
        event.preventDefault();
        this.setState({
            pan_start_x:event.pageX,
            pan_start_y:event.pageY,
            panning:true,
            // placing:true,
        })
    }

    mapMouseMove = (event) => {
        event.preventDefault();
        if (this.state.panning) {
            // Get mouse movement delta's and apply them to camera positions
            let new_x = this.state.cam_x + (event.pageX - this.state.pan_start_x);
            let new_y = this.state.cam_y + (event.pageY - this.state.pan_start_y);
            this.setState({
                cam_x:new_x,
                cam_y:new_y,
                pan_start_x:event.pageX,
                pan_start_y:event.pageY,
                placing:false,
            })
        }
    }

    mapMouseWheel = (event) => {
        event.preventDefault();
        let curr_time = new Date();
        // Zoom event throttled to 50 milliseconds
        if (curr_time.getTime() - scroll_throttle.getTime() > 50) {
            scroll_throttle = curr_time;
            let new_zoom = this.state.zoom;
            let new_x = this.state.cam_x;
            let new_y = this.state.cam_y;
            
            // Zoom IN
            if (event.deltaY < 0) {
                new_zoom = Math.min(MAX_ZOOM, new_zoom+0.25);
            }
            // Zoom OUT
            else {
                new_zoom = Math.max(MIN_ZOOM, new_zoom-0.25);
            }

            // Shift Camera positions so that focal point remains the same
            if (new_x !== 0) {
                new_x = new_x*zoomScale(new_x, new_zoom)/zoomScale(new_x, this.state.zoom);
            }
            if (new_y !== 0) {
                new_y = new_y*zoomScale(new_y, new_zoom)/zoomScale(new_y, this.state.zoom);
            }
            
            this.setState({zoom:new_zoom, cam_x:new_x, cam_y:new_y})
        }
    }

    mouseUp = (event) => {
        if (this.state.placing === true && this.state.moving_point === false) {
            // let node = ReactDom.findDOMNode(this);
            let container = document.getElementById("map");
            let cont_rect = container.getBoundingClientRect();
            let height = zoomScale(this.state.height, this.state.zoom);
            let xOffset = 0;
            let yOffset = -1*window.pageYOffset;
            let point_x = event.pageX-cont_rect.left +xOffset;
            let point_y = height-(event.pageY-cont_rect.top + yOffset);
            if (this.state.zone_selected !== -1) {
                let zones = this.state.zones;
                let zone_selected = this.state.zone_selected;
                let point = {
                    x:inverseZoomScale(point_x, this.state.zoom),
                    y:inverseZoomScale(point_y, this.state.zoom),
                }
                zones[zone_selected].polygon.push(point);
                this.setState({panning:false, placing:false, zones:zones});
            }
            else {
                this.setState({panning:false, placing:false});
            }
        }
        else {
            this.setState({panning:false, placing:false, moving_point:false});
        }
    }

    // SELECTS
    getSites = () => {
        if (security === "0") {
            // console.log("getting all sites");               
            fetch(`${database}/sites/?token=${token}`)
                .then(response => response.json())
                .then(sites => this.setState({ sites: sites.data }))
                .catch(err => console.error(err));
        }
        else {
            console.log("user id::", user_id);
            fetch(`${database}/users/userId?token=${token}&user_id=${user_id}`)
            .then(response => response.json())
            .then(user => {
                let site_id = user.data[0].site_id;
                fetch(`${database}/sites/site?token=${token}&site_id=${site_id}`)
                .then(response => response.json())
                .then((sites) => {
                    this.setState({ 
                        sites: sites.data,
                     });
                })
                .catch(err => console.error(err));
            })
            .catch(err => console.error(err)); 
        }         
    }

    // Once a site has been selected load floor data and update state for rendering
    loadFloors = (site_idx) => {
        // also, get devices for this site
        this.getDevices(this.state.sites[site_idx].id);

        this.setState({
            floor_idx: "DEFAULT",
            zone_selected: -1,
            zone_selected_id:"DEFAULT",
            zone_calibration_begun: false,
        })
        let site_id = this.state.sites[site_idx].id;
        fetch(`${database}/floors/by_site?token=${token}&site_id=${site_id}`)
        .then( response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then( (floors) => {
            if (floors.data.length>0){                   
                let floors_array = [];
                for (let j=0; j<floors.data.length; j++) {
                    let floor = {
                        id:floors.data[j].id,
                        name:floors.data[j].name,
                        level:floors.data[j].level,
                        floorplan:floors.data[j].floorplan,
                        mime_type:floors.data[j].mime_type,
                        file_size:floors.data[j].file_size,
                        height:floors.data[j].height,
                        width:floors.data[j].width,
                    }
                    floors_array.push(floor);
                }
                // sort floors by level
                floors_array = floors_array.sort(function(a,b){return a.level - b.level});
                this.setState({
                    site_id:this.state.sites[site_idx].id,
                    floors:floors_array,
                    // current_floor:floors_array[0],
                    floor_idx: "DEFAULT",
                    zone_selected: -1,
                    zone_selected_id:"DEFAULT",
                    showSelects:false,
                    atTopFloor:false,
                    atBottomFloor:true,
                    tracked_patient:9999,
                    
                });
                // display lowest floor
                // let lowestFloor = 999;
                // for (let i=0; i< floors_array.length; i++){
                //     if (floors_array[i].level < lowestFloor) {
                //         lowestFloor = floors_array[i].level;
                //     }
                // }
                // this.loadFloorDetails(lowestFloor); 
            }
            else {
                console.log("site", site_id, "has no floors");
            }
        }).catch(function(err) {
            console.log(err);
        }); 

    }

    // Once a site has been selected load floor data and update state for rendering
    loadFloorDetails = (level) => {
        this.setState({
            zone_selected: -1,
            zone_calibration_begun: false,
        })
        // console.log("Loading floor details for level", level);
        let floors = this.state.floors;
        let floor_idx = null;
        for (let i=0; i < floors.length; i++) {
	    // console.log(floors[i].level);
        if (String(floors[i].level) === String(level)) {
            floor_idx = i;
            break;
        }
        }
        // console.log("floor index is:", floor_idx);
        if (floor_idx === null) {
            // console.log(JSON.stringify(event.target.value));
            return;
        }
        let that = this;
	    // console.log("Floor idx", floor_idx);

        // Update site details in state
        let zoom = zoomToFit(floors[floor_idx].width, floors[floor_idx].height, MAP_WIDTH, MAP_HEIGHT);
        let width = floors[floor_idx].width;
        let height = floors[floor_idx].height;
        let floor_id = floors[floor_idx].id;
        image_src = floors[floor_idx].floorplan;
        MIN_ZOOM = Math.round(zoom);
        MAX_ZOOM = Math.round(zoom)+5;

        that.setState({
            map_img:"data:image/bmp;base64,"+this.state.floors[floor_idx].floorplan,
            zoom:zoom,
            width:width,
            height:height,
            cam_x:0,
            cam_y:0,
            floor_idx:floor_idx,
            zone_selected: -1,
            zone_selected_id:"DEFAULT",
            current_floor:floors[floor_idx],
        });
        that.getZones(floor_id);
    }

    // Get zones for selected floor
    getZones = (floor_id) => {
        let that = this;
        // get all zones in sql db for this floor
        fetch(`${database}/zones/by_floor?token=${token}&floor_id=${floor_id}`)
        .then(response => response.json())
        .then((zones) => {
            // console.log("getZones() messages");
            // console.group();
            if (zones.data.length > 0) {
                // console.log("Retrieved zones", zones.data);
                let zones_array = [];
                for (let i=0; i<zones.data.length; i++) {
                    let polygon = JSON.parse(zones.data[i].polygon);
                    let safety_text = "Alert";
                    if (zones.data[i].safety_level === "3") {
                        safety_text = "Safe";
                    }
                    else if (zones.data[i].safety_level === "2") {
                        safety_text = "Warn";
                    }
                    let zone = {
                        id: zones.data[i].id,
                        name: zones.data[i].name,
                        color: zones.data[i].color,
                        safety_level: zones.data[i].safety_level,
                        safety_text: safety_text,
                        polygon: polygon,
                    }
                    // console.log("zone:", zone);
                    // console.log("polygon data:", polygon);
                    zones_array.push(zone);                        
                }
                // console.log("zones array:", zones_array);
                // sort newest zones to top of table
                zones_array = zones_array.sort(function(a,b){return b.id - a.id});
                that.setState({zones:zones_array});
            }
            else {
                console.log("floor", floor_id, "has no zones");
                that.setState({zones:[]});
            }
            // console.groupEnd();
        })
        .catch(err => console.error(err));    
    }

    // after zone is selected
    loadZoneDetails = (zone_id) => {
        // console.log("ZONE ID INPUT::", zone_id);
        let zones = this.state.zones;
        for (let i=0; i<zones.length; i++) {
            // console.log("ZONE::", zones[i].id);
            if (zones[i].id === zone_id) {
                this.setState({
                    zone_selected_id:zones[i].id,
                    zone_selected:i,
            });                
            }
        }
    }

    getFloorplan = () => {                  
        // console.log("floor id", floor_id);
        fetch(`${database}/floors/by_floor?token=${token}&floor_id=${floor_id}`)
        .then( response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then( (floors) => {
            if (floors.data.length>0){
                image_src = floors.data[0].floorplan.replace(/\s/g, '');
                this.setState({
                    building_idx:site_id,
                    floor_idx:floors.data[0].level,
                    zone_selected: -1,
                    zone_selected_id:"DEFAULT",
                    floor_width: floors.data[0].width,
                    floor_height: floors.data[0].height,
                });
                this.loadFloorDetails();
            }
            else {
                console.log("Did not find floor with floor_id:", floor_id);
            }
        }).catch(function(err) {
            console.log(err);
        });      
    }

    getDevices = (site_id) => {
        // console.log("getDevices site::", site_id);
        fetch(`${database}/devices/site/?token=${token}&site_id=${site_id}`)
        .then(response => response.json())
        .then((devices) => {
            // console.log("DEVICES:", devices);
            let temp_devices = [];
            
            for (let i=0; i<devices.data.length; i++) {
                temp_devices.push({
                    id:devices.data[i].id, 
                    name:devices.data[i].name,
                    MAC_address: devices.data[i].MAC_address,
                    status: devices.data[i].is_connected,
                })
            }
            this.setState({ devices: temp_devices})                
        })
        // .then(console.log("getDevices:", this.state.devices))
        .catch(err => console.error(err));
    }
    
    logDevice = (MACaddress) => {
        // console.log("Selected MAC Address:", MACaddress);
        // find device db id to use durning calibration to check for lost connection
        let tempDevices = this.state.devices;
        let selectedDevice = null;
        let selectedDeviceName = "";
        for (let i = 0; i < tempDevices.length; i++) {
            if (tempDevices[i].MAC_address === MACaddress) {
                selectedDevice = tempDevices[i].id;
                selectedDeviceName = tempDevices[i].name;
            }
        }

        this.setState({
            device_MAC: MACaddress,
            selected_device_MAC: MACaddress,
            selected_device: selectedDevice,
            selected_device_name: selectedDeviceName,
        }); 
    }
    
    // CALIBRATION
    beginCalibration = (device) => {
        this.setState({
            calibrating:true,
            zone_calibration_begun: true,
            calibrating_msg:"End Calibration",
            record_count: 0,
            record_count_message: "Calibration beginning - please wait.",
            size_warning: null,
            skewed_warning: null,
        });
        // console.log("Device is:", this.state.device_MAC);
        // send start calibrating request to backend server
        var url = window.location.href;
        //url = url.split('http://')[1];
        //url = url.split(':')[0];
        //url = "http://"+url+":1556";
        //url = "https://www.the3dex.com/python";
	url = "https://www.activlinkcare.com/python/";
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.open("POST", url, true);
        let device_MAC = this.state.device_MAC;
        let zone_id = this.state.zone_selected_id;
        var params_string = "cmd_name=calibration&cmd_type=start";
        params_string += "&mac=" + device_MAC;
        params_string += "&zone_id=" + zone_id;
        xmlhttp.send(params_string);

        this.calibrateZone();
    }

    calibrateZone = () => {
        this.setState({
            record_count: 0,
        })

        // console.log("calibrateZone()");
        // console.log("calibrating:", this.state.calibrating);

        let zone_id = this.state.zones[this.state.zone_selected].id;
        let zone = this.state.zones[this.state.zone_selected];
        // console.log("Calibrate Zone", this.state.zone_name_input, "db index:", zone_id);
        // console.log("Zone:", zone);

        // create grids for this zone if they don't already exist.  They will only exist if 
        // calibration has been run and the zone wasn't updated in the meantime.
        fetch(`${database}/zones/grids_by_zone?token=${token}&zone_id=${zone_id}`)
        .then(response => response.json())
        .then((grids) => {

            if (grids.data.length > 0) {
                // gridsFound = true;
                // console.log("Using grids from db");
            } else {
                // console.log("No grids found for this zone, adding new")
                if (window.confirm("This zone does not have previous calibration data.  It could take several minutes to grid this zone.")) {

                    this.setState({
                        record_count_message: "Gridding zone...",
                    });
                    
                    if (!this.state.calibrating) {
                        // console.log("start calibrating");

                        this.setState({
                            calibrating:!this.state.calibrating,
                            calibrating_msg:"End Calibration",
                        });
                    }

                    let xArray = [];
                    let yArray = [];
                    for (let i = 0; i < zone.polygon.length; i++) {
                        xArray.push(zone.polygon[i].x);
                        yArray.push(zone.polygon[i].y);
                    }
                    // console.log("x array",xArray);
                    // console.log("y array",yArray);
                    let left = Math.floor(Math.min(...xArray));
                    let right = Math.ceil(Math.max(...xArray));
                    let top = Math.ceil(Math.max(...yArray));
                    let bottom = Math.ceil(Math.min(...yArray));
                    // console.log("left:", left);
                    // console.log("right:", right);
                    // console.log("top:", top);
                    // console.log("bottom:", bottom);
                    
                    for (let x=left; x<=right; x++) {
                        for (let y=bottom; y<=top; y++) { 
                            let grid = {x:x, y:y};
                            //store grid in sql database
                            fetch(`${database}/zones/add_grid?token=${token}&zone_id=${zone_id}&x=${x}&y=${y}`, {method:'POST'})
                            .then(function(results) {
                                console.log("Adding grid:", grid);
                            })
                            .catch(err => console.log("Error storing grid:",err));
                        }
                    }
                }
            } 
            this.setState({record_count_message: "Calibration beginning - please wait."});
            this.getCalibrationAnalytics(this.state.zone_selected_id);

        })
        .catch(err => {
            console.error("Error looking up grids:",err);
        });
    }

    stopCalibration = () => {
        // console.log("stop calibrating");
        // send stop calibrating request to backend server
        if (window.confirm("Are you sure you want to stop calibrating this zone?")) {
            this.endCalibration();
        }

    }

    endCalibration = () => {
        // console.log("stop calibrating");
        // send stop calibrating request to backend server on abend
        if (interval_handle) {
            clearInterval(interval_handle);
        }
        var url = window.location.href;
        //url = url.split("http://")[1];
        //url = url.split(':')[0];
        //url = "http://"+url+":1556";
        //url = "https://www.the3dex.com/python";
	url = "https://www.activlinkcare.com/python/";
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.open("POST", url, true);
        let device_MAC = this.state.device_MAC;
        // let zone_id = this.state.zone_selected_id;
        var params_string = "cmd_name=calibration&cmd_type=stop";
        params_string += "&mac=" + device_MAC;
        xmlhttp.send(params_string);
        clearInterval(this.interval);
        this.setState({
            calibrating:!this.state.calibrating,       
            calibrating_msg:"Begin Calibration",
            record_count_message: "",
        });
        return;

    }

    getCalibrationAnalytics = (zone_id) => {
        let that = this;
        if (interval_handle) {
            clearInterval(interval_handle);
        }
        let bssidData = [];
        let zone_array = [];
        let timeoutCount = 0;
        
        // get calibration analytics every 15 seconds
        interval_handle = setInterval(function(){

            // check to see if device is still connected
            let selectedDevice = that.state.selected_device;
            if (selectedDevice) {
                fetch(`${database}/devices/id?token=${token}&device_id=${selectedDevice}`)
                .then(response => response.json())
                .then((device) => {
                    console.log("SELECTED DEVICE::", selectedDevice);
                    console.log("DEVICE CONNECTED::", device.data[0].is_connected);
                    console.log("TIMEOUT COUNT::", timeoutCount);
                    if (device.data[0].is_connected === 0) {
                        // progress animation...
                        let tempEllipses = that.state.ellipses;
                        if (tempEllipses.length > 2) {
                            tempEllipses = ".";
                        }
                        else {
                            tempEllipses += ".";
                        }
                        that.setState({
                            device_connected: false,
                            ellipses: tempEllipses,
                        });
                        timeoutCount += 1;
                        // timeout after 3 minutes...
                        if (timeoutCount > 11) {
                            if (window.confirm("Unable to reestablish a connection to the insole device.  Please refer to the user manual instructions for connecting a device.")) {
                                timeoutCount = 0;
                                that.setState({device_connected:true});
                                that.endCalibration();
                                window.location.href="/calibrations";
                            }
                        }

                    }
                    else {
                        that.setState({
                            device_connected:true,
                        });
                        timeoutCount = 0;
                    }
                })
                .catch(err => console.error(err));
            }
        
            bssidData = [];
            // count calibration records for zone
            fetch(`${database}/calibrations/count_by_zone?token=${token}&id=${zone_id}`)
            .then(response => response.json())
            .then((recordCount) => {
                let count = Object.values(recordCount.data[0]);
                // console.log("COUNT::", count[0]);
                that.setState({record_count:count});
                let zoneSizeWarning = "";
                let zoneSkewedWarning = "";
                // get unique bssids for zone
                fetch(`${database}/calibrations/bssid_by_zone?token=${token}&id=${zone_id}`)
                .then(response => response.json())
                .then((bssids) => {
                    // get all calibration records for this zone
                    fetch(`${database}/calibrations/zone?token=${token}&id=${zone_id}`)
                    .then(response => response.json())
                    .then((records) => {
                        // cycle thru bssids
                        for (let i=0; i<bssids.data.length; i++) {
                            let count = null;
                            let min = 0;
                            let max = -100;
                            let rssiCuml = 0;
                            let rssiArray = [];
                            // cycle through calibration records
                            for (let j=0; j<records.data.length; j++) {
                                // accumulate data if the calibration record bssid matches current bssid
                                if (records.data[j].bssid === bssids.data[i].bssid) {
                                    count++;
                                    rssiCuml = rssiCuml + records.data[j].rssi;
                                    // used later to get median
                                    rssiArray.push(records.data[j].rssi);
                                    // get min
                                    if (records.data[j].rssi < min) {
                                        min = records.data[j].rssi;
                                    }
                                    // get max
                                    if (records.data[j].rssi > max) {
                                        max = records.data[j].rssi;
                                    }
                                }
                            }
                            // find median
                            rssiArray.sort();
                            let medianIndex = null;
                            if(rssiArray.length % 2) {
                                medianIndex = (rssiArray.length+1)/2
                            }
                            else {
                                medianIndex = (rssiArray.length)/2
                            }
                            let median = null;
                            if (count === 1) {
                                median = min;
                            } 
                            else {
                                median = rssiArray[medianIndex];
                            }
                            let avg = Math.round(rssiCuml / count);

                            if (Math.abs(min - max) > 60) {
                                zoneSizeWarning = "This zone may be too large.";
                            }
                            else {
                                zoneSizeWarning = "";
                            }

                            if ((min !== max)&&(count>8)) {
                                if ((Math.abs(min - median)<=5) || (Math.abs(max - median)<=5)) {
                                    // console.log("MIN:", min, "MEDIAN:", median, "MAX:", max);
                                    // console.log("Zone", zone_id, "may not have been calibrated completely.");
                                    zoneSkewedWarning = "The area of this zone may not be completely calibrated yet.";
                                }
                                else {
                                    zoneSkewedWarning = "";
                                }
                            }

                            let bssid_data = {
                                zone_id: zone_id,
                                bssid: bssids.data[i].bssid,
                                count: count,
                                min: min,
                                max: max,
                                avg: avg,
                                median: median,
                            }
                            bssidData.push(bssid_data);
                            that.setState({});
                        }
                    })
                    .then(() => {
                        let zoneCountWarning = null;
                        let zoneCount = count[0];
                        let avgCount = count[0]/bssids.data.length
                        if (!count[0] || avgCount < 40) {
                            // zoneCountWarning = "Record count is low: " + count[0];
                            zoneCountWarning = "Average Record count is low: " + Math.round(count[0]/bssids.data.length);                            
                        }
                        else {
                            // zoneCountWarning = "Record count is good: " + count[0];
                            zoneCountWarning = "Average Record count is good: " + Math.round(count[0]/bssids.data.length);                           
                        }
                        that.setState({
                            record_count_message: zoneCountWarning,
                            size_warning: zoneSizeWarning,
                            skewed_warning: zoneSkewedWarning,
                        });
                        // console.log("Writing zone data for", zone.data[0].id);
                        // console.log("Count Warning:", zoneCountWarning);
                        // console.log("Size Warning:", zoneSizeWarning);
                        // console.log("Skewed Warning:", zoneSkewedWarning);
                        let zoneData = {
                            record_count: count[0],
                            bssid_count: bssids.data.length,
                            zoneCountWarning: zoneCountWarning,
                            zoneSizeWarning: zoneSizeWarning,
                            zoneSkewedWarning: zoneSkewedWarning,
                            bssid_data: bssidData,
                        }
                        zone_array.push(zoneData);
                        that.setState({});
                    })
                    .catch(err => console.error(err));
                })
                .catch(err => console.error(err));
            })
            .catch(err => console.error(err));

        }, 15000);

    }

    // This is needed for firefox, otherwise there are 3 distinct events when
    // selecting from a dropdown. This causes race conditions, use this to disable
    // the unneeded events.
    stopEventPropagation = (event) => {
	    event.stopPropagation();
    }

    render() {
        // let that = this;
        let width = zoomScale(this.state.width, this.state.zoom);
        let height = zoomScale(this.state.height, this.state.zoom);
        let zoom = this.state.zoom;
        let cam_x = this.state.cam_x;
        let cam_y = this.state.cam_y;

        return (
            <div id="calibration-container" onMouseUp={this.mouseUp}>

                <Header />

                <div className="page-title">{pageTitle}</div> 

                <div id="calibration-wrapper">

                    {this.state.device_connected &&
                    <section className="calibration-controls">

                    <div className="calibration-select">
                        <div className="calibration-labels">
                            <div id="calibration-site-label" className="calibration-label">Site</div>
                        </div>
                        <select 
                            id="calibration-site-select" 
                            className="calibration-select" 
                            onChange={(event) =>{this.loadFloors(event.target.value)}} 
                            defaultValue={"DEFAULT"}
                        >
                            <option 
                                className="disabled"
                                value={"DEFAULT"} 
                                disabled
                            >Select a Site</option>
                            {this.state.sites && this.state.sites.map((value, index) => {
                                if (value.name !== "All Sites") {
                                    return (
                                        <option value={index} style={{color:"black"}} key={index}>{value.name}</option>
                                    )
                                }
                            })}
                        </select>
                    </div>
                    
                    {this.state.site_id &&
                    <div className="calibration-select">
                        <div className="calibration-labels">
                            <div id="calibration-floor-label" className="calibration-label">Floor</div>
                        </div>
                        <select 
                            id="calibration-floor-select" 
                            className="calibration-select" 
                            onChange={(event) =>{this.loadFloorDetails(event.target.value)}} 
                            value={this.state.floor_idx}
                        >                            
                            <option 
                                className="disabled"
                                value={"DEFAULT"} 
                                disabled
                            >Select a Floor</option>
                            {this.state.floors && this.state.floors.map((value, index) => {
                                return (
                                <option value={value.level} style={{color:"black"}} key={index}>{value.name}</option> 
                                )
                            })}
                        </select>
                    </div>
                    }
                    
                    {this.state.floor_idx !== "DEFAULT" &&
                    <div className="calibration-select">
                        <div className="calibration-labels">
                            <div id="calibration-zone-label" className="calibration-label">Zone</div>
                        </div>
                        <select 
                            id="calibration-zone-select" 
                            className="calibration-select" 
                            onChange={(event) =>{this.loadZoneDetails(parseInt(event.target.value))}} 
                            value={this.state.zone_selected_id}
                        >                            
                            <option 
                                className="disabled"
                                value={"DEFAULT"} 
                                disabled
                            >Select a zone to calibrate</option>
                            {this.state.zones && this.state.zones.map((value, index) => {
                                return (
                                <option value={value.id} style={{color:"black"}} key={index}>{value.name}</option> 
                                )
                            })}
                        </select>
                    </div>
                    }

                    {this.state.zone_selected !== -1 &&
                    <div id="map-row">
                        <div 
                            id="zone-map-wrapper"
                            style={{width:MAP_WIDTH, height:MAP_HEIGHT, position: "relative",margin:"auto", overflow:"hidden"}}
                        >
                            <img
                                id="map"
                                style={{ 
                                    position:"absolute",
                                    transform:'rotate('+this.state.direction+"deg)",
                                    left:MAP_WIDTH/2-width/2 + cam_x,
                                    top:MAP_HEIGHT/2-height/2 + cam_y
                                }}
                            
                                height={height}
                                width={width}
                                src={"data:image/bmp;base64,"+image_src}
                                alt=""
                            />
                            <svg
                                onMouseDown={this.mapMouseDown}
                                onMouseMove={this.mapMouseMove}
                                onWheel={this.mapMouseWheel}
                                style={{position:"absolute", width:MAP_WIDTH, height:MAP_HEIGHT, left:0, top:0}}
                            >
                                <g id="map-bounds" transform={"translate("+(MAP_WIDTH/2-width/2+cam_x)+","+(MAP_HEIGHT/2+height/2+cam_y)+")"}>

                                    {this.state.zones && this.state.zones.map((zone, index) => {
                                        let points = "";
                                        var idx;
                                        for (idx in (zone.polygon)) {
                                            let point = zone.polygon[idx];
                                            points += (zoomScale(point.x, zoom)) + "," + (-1*zoomScale(point.y, zoom)) + " ";
                                        }
                                        //console.log("map polygon", index, "points:", points);
                                        return (
                                        <g className="zone-bounds" key={zone.id}>
                                            <polygon points={points} style={{stroke:zone.color,strokeWidth:2, strokeOpacity:index===this.state.zone_selected?.1:.3, fill:index===this.state.zone_selected?zone.color:'none'}}/>
                                        </g>
                                        )
                                    })}
                    
                                </g>
                            </svg>
                        </div>
                    </div>
                    }
                    </section>
                    }

                    {this.state.zone_selected !== -1 && this.state.device_connected &&
                    <section className="calibration">

                        <div className="calibration-select">
                            <div className="calibration-labels">
                                <div id="calibration-device-label" className="calibration-label">Device</div>
                            </div>
                            <select 
                                id="calibration-device-select" 
                                className="calibration-select" 
                                onChange={(event) =>{this.logDevice(event.target.value)}} 
                                defaultValue={9999}
                            >                            
                                <option 
                                    className="disabled"
                                    value={9999} 
                                    disabled
                                >Select a device to use in calibration</option>
                                {this.state.devices && this.state.devices.map((value, index) => {
                                    let optionString = "";
                                    if (value.status === 1){
                                        optionString = <option value={value.MAC_address} style={{color:"black"}} key={index}>{value.name}</option> 
                                    }
                                    else {
                                        optionString = <option value={value.MAC_address} disabled style={{color:"#bdbdbd"}} key={index}>{value.name} not connected</option> 
                                    }
                                    return (
                                        optionString
                                    )
                                })}
                            </select>
                        </div>
                        {this.state.selected_device_MAC&&
                        <button 
                            id="calibrate-button"
                            className="calibration-btn"
                            onClick={this.state.calibrating?this.stopCalibration:this.beginCalibration}
                            style={{backgroundColor:this.state.calibrating?"#47404d":"#3dae2b"}}
                        >{this.state.calibrating_msg}</button>
                        }

                    </section>
                    }

                    {this.state.zone_calibration_begun && this.state.device_connected &&
                    <section id="calibration-analytics-section">
                        <div className="calibration-analytics-header">Calibration Analytics</div>
                        {this.state.record_count > 0 && 
                        <div className="record-count">Record Count: {this.state.record_count}</div>                        
                        }
                        
                        <div className="record-count-message">{this.state.record_count_message}</div>
                                              
                        {this.state.record_count>0&&this.state.size_warning&&
                        <div className="zone-size-warning">{this.state.size_warning}</div>
                        }
                        {this.state.record_count>0&&this.state.skewed_warning&&
                        <div className="zone-skewed-warning">{this.state.skewed_warning}</div>
                        }
                        {this.state.record_count>0 && (this.state.size_warning || this.state.skewed_warning) &&
                        <div className="calibration-diagnostics-msg">See Diagnostics for more information.</div>
                        }                        
                        
                    </section>
                    }

                    {this.state.zone_calibration_begun && !this.state.device_connected &&
                    <section id="lost-connection-section">
                    <div className="lost-connection-messages">
                        <div className="lcm1">The device {this.state.selected_device_name} has lost connection.</div>
                        <div className="lcm2">Please wait{this.state.ellipses}</div>
                    </div>
                    </section>
                    }

                </div>

                <Footer />
            </div>
        );
    }
}

export default Calibrations;
