import React, { Component } from 'react';
import './Header.css';
// import Setup from '../Setup';
import Modal from 'react-modal';
import logo from '../../images/activlink_logo_dark.png';
import menu_icon from '../../images/menu.svg';
import back_arrow from '../../images/back_arrow.svg'
// import { relativeTimeThreshold } from 'moment';
require('dotenv').config();
var security = sessionStorage.getItem("security");
var isLoggedIn = sessionStorage.getItem("isLoggedIn");

// styling for react-modal
const customStyles = {
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '300px', 
        minHeight: '400px',
        border: '1px solid black',
        borderRadius: 'none',
        padding: '0px',
    }
};

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logoutModalIsOpen: false,
            isMenuOpen: false,
        }

        this.openLogoutModal = this.openLogoutModal.bind(this);
        this.afterOpenLogoutModal = this.afterOpenLogoutModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.logoutFunc = this.logoutFunc.bind(this);
    }

    openLogoutModal() {
        this.setState({
            logoutModalIsOpen: true,
        });
    }
    
    afterOpenLogoutModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({
            logoutModalIsOpen: false,
        });
    }

    componentDidMount() {
        this.getRoles();
    }

    getRoles = () => {
        fetch('/users/roles')
            .then(response => response.json())
            .then(roles => this.setState({ roles: roles.data }))
            .catch(err => console.error(err));        
    }

    logoutFunc() {
        sessionStorage.setItem("isLoggedIn", "false");
        sessionStorage.setItem("security", null);
        this.isLoggedIn = false;
        this.setState({
            // isLoggedIn: sessionStorage.getItem("isLoggedIn"),
            loginErrors: false,
            errorMsg: "",
        }, () => {
            this.closeModal();
            window.location.href="/";
        });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    showLogout = () => {
        // not all browsers will store boolean values so we are storing and checking for string values
        let loginStatus = sessionStorage.getItem("isLoggedIn");
        if (loginStatus === null) {
            loginStatus = "false";
        }
        const logoutHtml = (<div className="header-item" onClick={() => this.openLogoutModal()}>Logout</div>);
        if (loginStatus === "true"){
            return logoutHtml;
        } 
    }

    openNav = () => {
        document.getElementById("activlinkSidenav").style.width = "260px";
        this.setState({isMenuOpen: true});
    }

    closeNav = () => {
        document.getElementById("activlinkSidenav").style.width = "0";
        this.setState({isMenuOpen: false});
    }

    render() {
        return (
        <div id="header-wrapper">

            <div className="header-item">
                {this.state.isMenuOpen?
                <img id="menu-icon" src={menu_icon} alt="Menu" onClick={this.closeNav} />
                :
                <img id="menu-icon" src={menu_icon} alt="Menu" onClick={this.openNav} />
                }

            </div>

            <img id="logo" src={logo} height="40px" width="30px" alt="Activlink" onClick={()=>{window.location.href="/live"}}/>

            { this.showLogout() }

            {sessionStorage.getItem("security")==="0"&&
            <div id="activlinkSidenav" className="sidenav" onClick={this.closeNav}>
                <div className="closebtn" onClick={this.closeNav}>{this.back_arrow}</div><hr/>
                <a className="menu-item select-live-view" href="/live" style={{fontSize:"32px"}}>Live View</a><hr/>
                <a className="menu-item" href="/notifications">Manage Notifications</a><hr/>
                <a className="menu-item" href="/sites">Manage Sites</a><hr/>
                <a className="menu-item" href="/patients">Manage Patients</a><hr/>
                <a className="menu-item" href="/users">Manage Users</a><hr/>
                <a className="menu-item" href="/devices">Manage Devices</a><hr/>
                <a className="menu-item" href="/calibrations">Calibrate Zones</a><hr/>
                <a className="menu-item" href="/preferences">Preferences</a><hr/>
                <a className="menu-item" href="/diagnostics">Diagnostics</a><hr/>
                <a className="menu-item" href="/surveys">Caregiver Surveys Report</a><hr/>
                {process.env.REACT_APP_STATION?
                <div>
                    <a className="menu-item" onClick={()=>{window.location.href="/backup"}}>
                        Backup
                    </a>
                    <hr/>
                </div>
                :
                ""
                }
            </div>
            }

            {sessionStorage.getItem("security")==="3"&&
            <div id="activlinkSidenav" className="sidenav" onClick={this.closeNav}>
                <div className="closebtn" onClick={this.closeNav}>{this.back_arrow}</div><hr/>
                <a className="menu-item select-live-view" href="/live" style={{fontSize:"32px"}}>Live View</a><hr/>
                <a className="menu-item" href="/sites">Manage Sites</a><hr/>
                <a className="menu-item" href="/calibrations">Calibrate Zones</a><hr/>
                <a className="menu-item" href="/diagnostics">Diagnostics</a><hr/>
                {process.env.REACT_APP_STATION?
                <div>
                    <a className="menu-item" onClick={()=>{window.location.href="/backup"}}>
                        Backup
                    </a>
                    <hr/>
                </div>
                :
                ""
                }
            </div>
            }

            {(sessionStorage.getItem("security")==="1" || sessionStorage.getItem("security" === "2")) &&
            <div id="activlinkSidenav" className="sidenav" onBlur={this.closeNav}>
                <div className="closebtn" onClick={this.closeNav}>{this.back_arrow}</div><hr/>
                <a className="menu-item select-live-view" href="/live">Live View</a><hr/>
                <a className="menu-item" href="/notifications">Manage Notifications</a><hr/>
            </div>
            }

            <div id="modals-wrapper">

                <Modal
                    id="logout-modal"
                    isOpen={this.state.logoutModalIsOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="logout"
                    style={customStyles}
                >

                    <div id="logout-modal-content">
                        <div className="modal-header">
                            <div className="logout-header">Logout</div>
                        </div>

                        <div className="log-message"><p>Are you sure you want to logout?</p></div>

                        <button 
                            id="logout-btn" 
                            onClick={this.logoutFunc}> 
                            Logout
                        </button>
                        
                        </div> 

                        <button 
                            id="cancel-logout-btn" 
                            onClick={this.closeModal}>
                            Cancel
                        </button>

                </Modal>

            </div>

        </div> 
        )
    }
}

export default Header;
