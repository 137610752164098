import React, { Component } from 'react';
import '../common.css';
import './Devices.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Tooltip } from 'antd';
import {setRefreshInterval, clearRefreshInterval} from '../RefreshInterval';
var refreshInterval = null;
var token = sessionStorage.getItem("token");
var refreshAccessToken = function(value) {token = value};

var sites_promise = [];
var patients_promise = [];
const database = sessionStorage.getItem("database");

class Devices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: [],
            MAC_addresses: [],
            sites: [],
            patients: [],
            device: {
                name: "",
                MAC_address: "",
            },
            name: "",
            MAC_address: "",
            site_id: null,
            editing_site: false,
            edit_changes_made: false,
            site_name: "",
            patient_id: null,
            patient_name: "",
            id: "",
            adding_device: false,
            editing_device: false,
            change_MAC: false,
            change_patient: false,
        };
    
        this.addDevice = this.addDevice.bind(this);
        this.editDevice = this.editDevice.bind(this);
        this.viewEditDevice = this.viewEditDevice.bind(this);       
        this.viewAddDevice = this.viewAddDevice.bind(this);
    }

    viewEditDevice(device) {
        console.log("viewEditDevice(), device is:", device);
        this.setState({
            editing_device: true,
            edit_changes_made: false,
            adding_device: false,
            name:device.name,
            MAC_address: device.MAC_address,
            site_id: device.site_id,
            editing_site:false,
            site_name: device.site_name,
            patient_id: device.patient_id,
            patient_name: device.patient_name,
            id:device.id,
        });
        console.log("site:", device.site_id, device.site_name, "patient:", device.patient_id, device.patient_name);
    }

    viewAddDevice() {
        this.setState({
            adding_device: true,
            editing_site: false,
        });
    }
  
    addEditComplete = () => {
        this.setState({
            editing_device: false, 
            edit_changes_made: false,
            adding_device: false,
            device: {name:"", MAC_address:""},
            name: "",
            MAC_address:"", 
            site_id:null,
            editing_site:false,
            patient_id:null, 
            patient_name:"",
            site_name:"",
            change_MAC: false,
            change_patient: false,
        });
    }

    componentDidMount() {
        this.getSites();
        this.getPatients();
        this.getDevices();
        setRefreshInterval(database, refreshInterval, refreshAccessToken);
    }

    componentWillUnmount() {
        clearRefreshInterval(refreshInterval);
    }

    getDevices = () => {
        Promise.all(sites_promise).then(() => {
            Promise.all(patients_promise).then(() => {
            fetch(`${database}/devices/?token=${token}`)
                .then(response => response.json())
                .then((devices) => {
                    let temp_sites = this.state.sites;
                    let temp_patients = this.state.patients;
                    let temp_devices = [];
                    let temp_site_id;
                    let temp_site_name;
                    let temp_patient_id;
                    let temp_patient_name;
                    let temp_MAC_addresses =[];
                    for (let i=0; i<devices.data.length; i++) {
                        for (let j=0; j<temp_sites.length; j++) {
                            if (devices.data[i].site_id===temp_sites[j].id) {
                                temp_site_id = temp_sites[j].id;
                                temp_site_name = temp_sites[j].name;
                                break;
                            }
                            else {
                                temp_site_id = -1;
                                temp_site_name = "None";
                            }
                        }
                        for (let j=0; j<temp_patients.length; j++) {
                            if (devices.data[i].patient_id===temp_patients[j].id) {
                                temp_patient_id = temp_patients[j].id;
                                temp_patient_name = temp_patients[j].alias;
                                console.log("temp patient id is:", temp_patient_id);
                                break;
                            }
                            else {
                                temp_patient_id = -1;
                                temp_patient_name = "None";
                            }
                        }
                        temp_devices.push({
                            id:devices.data[i].id, 
                            name:devices.data[i].name,
                            MAC_address: devices.data[i].MAC_address,
                            site_id:temp_site_id,
                            site_name:temp_site_name,
                            patient_id:temp_patient_id,
                            patient_name:temp_patient_name,
                        })
                        temp_MAC_addresses.push(devices.data[i].MAC_address)
                    }
                    this.setState({ 
                        devices: temp_devices,
                        MAC_addresses: temp_MAC_addresses,
                    })                
                })
                .then(console.log("getDevices:", this.state.devices))
                .catch(err => console.error(err));
            })
        })
    }

    getSites = () => {
	sites_promise = [];
        sites_promise.push(fetch(`${database}/sites/?token=${token}`)
            .then(response => response.json())
            .then(sites => this.setState({ sites: sites.data }))
            .then(console.log("getSites:", this.state.sites))
			   .catch(err => console.error(err)));
    }
    
    getPatients = () => {
	    patients_promise = [];
        patients_promise.push(fetch(`${database}/patients/?token=${token}`)
            .then(response => response.json())
            .then(patients => this.setState({ patients: patients.data }))
            .then(console.log("getPatients:", this.state.patients))
			      .catch(err => console.error(err)));
    }

    removePatientFromDevices = (patient_id) => {
        for (let i=0; i<this.state.devices.length; i++) {
            if (this.state.devices[i].patient_id === patient_id) {
                let data = {
                    name: this.state.devices[i].name,
                    MAC_address: this.state.devices[i].MAC_address,
                    site_id: parseInt(this.state.devices[i].site_id),
                    patient_id: -1,
                    id: this.state.devices[i].id,
                }
                console.log("Removing patient:", patient_id, "from device:", this.state.devices[i].id);
                console.log("EDIT DATA::", data);
                fetch(`${database}/devices/edit?token=${token}&name=${data.name}&MAC_address=${data.MAC_address}&site_id=${data.site_id}&patient_id=${data.patient_id}&id=${data.id}`)
                .then( response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then(function(data) {
                    console.log(data)
                    // this.getDevices();
                })
                .catch(function(err) {
                    console.log(err);
                })
            }
        }
    }

    addDevice() {
        if (this.state.MAC_addresses.indexOf(this.state.MAC_address) !== -1) {
            alert("There is already a device with this MAC address.");
            return;
        }
        if (!this.state.site_id) {
            alert("Please select a site.");
            return;
        }
        if (!this.state.patient_id) {
            alert("Please select a patient.");
            return;
        } 
        else if (parseInt(this.state.patient_id,10) !== -1){
            let pt_id = parseInt(this.state.patient_id, 10);
            for (let i=0; i<this.state.devices.length; i++) {
                if (this.state.devices[i].patient_id === pt_id) {
                    if (window.confirm("This patient has a device assigned to them. Do you want to assign this device instead?")) {
                        console.log("REMOVING THIS PT FROM ALL DEVICES...");
                        this.removePatientFromDevices(pt_id);
                        break;
                    }
                    else {
                        alert("A patient can only have one assigned device.")
                        return;
                    }
                }
            }
        }
        let data = {
            name: this.state.name,
            MAC_address: this.state.MAC_address,
            site_id: this.state.site_id,
            patient_id: this.state.patient_id, 
        }
        fetch(`${database}/devices/add?token=${token}&name=${data.name}&MAC_address=${data.MAC_address}&site_id=${data.site_id}&patient_id=${data.patient_id}`)
        .then(this.getDevices)
        .catch(err => console.error(err));
        this.setState({
            device: {name:"", MAC_address:""},
            name: "",
            MAC_address:"", 
            site_id:"",
            editing_site:false,
            patient_id:"", 
            patient_name:"",
            site_name:"",
            edit_changes_made: false,
            adding_device: false,
        });
        this.addEditComplete();
    }

    editDevice() {
        if (this.state.change_MAC&&this.state.MAC_addresses.indexOf(this.state.MAC_address) !== -1) {
            alert("There is already a device with this MAC address.");
            return;
        }

        if (this.state.change_patient&&parseInt(this.state.patient_id,10) !== -1){
            let pt_id = parseInt(this.state.patient_id, 10);
            for (let i=0; i<this.state.devices.length; i++) {
                if (this.state.devices[i].patient_id === pt_id) {
                    if (window.confirm("This patient has a device assigned to them. Do you want to assign this device instead?")) {
                        console.log("REMOVING THIS PT FROM ALL DEVICES...");
                        this.removePatientFromDevices(pt_id);
                        break;
                    }
                    else {
                        alert("A patient can only have one assigned device.")
                        return;
                    }
                }
            }
        }

        let data = {
            name: this.state.name,
            MAC_address: this.state.MAC_address,
            site_id: parseInt(this.state.site_id),
            patient_id: parseInt(this.state.patient_id),
            id: this.state.id,
        }
        console.log("edit device data:", data);
        fetch(`${database}/devices/edit?token=${token}&name=${data.name}&MAC_address=${data.MAC_address}&site_id=${data.site_id}&patient_id=${data.patient_id}&id=${data.id}`)
        .then( response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then(function(data) {
            console.log(data)
            // this.getDevices();
        }).catch(function(err) {
            console.log(err);
        })

        if (this.state.editing_site) {
            if (window.confirm("You have changed this device's site.  Do you want to change the site for the patient as well?")) {
                let id = this.state.patient_id;
                let site_id = this.state.site_id;
                fetch(`${database}/patients/edit_site?token=${token}&id=${id}&site_id=${site_id}`)
                .then( response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then(function(data) {
                    console.log(data);
                    this.getPatients();
                }).catch(function(err) {
                    console.log(err);
                });
            }            
        }

        this.setState({
            device: {name:"", MAC_address:""},
            name: "",
            MAC_address:"", 
            site_id:"",
            patient_id:"", 
            patient_name:"",
            site_name:"",
            change_MAC: false,
            edit_changes_made: false,
            editing_site: false,
            change_patient: false,
        });
        this.getDevices();
        this.addEditComplete();
    }

    logChangeName = (e) => {
        // console.log("event",e.target.name, e.target.value);
        const backslash = "\\";
        if (e.target.value.length>30){
            alert("Device name cannot exceed 30 characters");
            return;
        }
        if (e.target.value.includes("#")||e.target.value.includes(backslash)||e.target.value.includes("+")||e.target.value.includes("'")) {
            alert("Device name cannot include these special characters: #, ', \\, +");
            e.target.value = e.target.value.replace("#", "");
            e.target.value = e.target.value.replace("'", "");
            e.target.value = e.target.value.replace("\\", "");
            e.target.value = e.target.value.replace("+", "");
        }
        this.setState({
            name: e.target.value,
            edit_changes_made: false,
        });  
    }

    logChangeMAC = (e) => {
        // console.log("event",e.target.name, e.target.value);
        const backslash = "\\";
        if (e.target.value.includes("#")||e.target.value.includes(backslash)||e.target.value.includes("+")||e.target.value.includes("'")) {
            alert("MAC address cannot include these special characters: #, ', \\, +");
            e.target.value = e.target.value.replace("#", "");
            e.target.value = e.target.value.replace("'", "");
            e.target.value = e.target.value.replace("\\", "");
            e.target.value = e.target.value.replace("+", "");
        }
        this.setState({
            MAC_address: e.target.value,
            change_MAC: true,
            edit_changes_made: false,
        });  
    }

    logSiteId = () => {
        // console.log(this.refs.siteSelect.value);
        this.setState({
            site_id: this.refs.siteSelect.value,
            editing_site: true,
            edit_changes_made: false,
        });  
    }

    logPatientId = () => {
        // console.log("New patient id:", this.refs.patientSelect.value);
        this.setState({
            patient_id: this.refs.patientSelect.value,
            change_patient: true,
            edit_changes_made: false,
        });  
    }

    render() {
        const {devices, patients, sites} = this.state;
        return (
            <div id="device-container">

                <Header/>

                <div className="page-title">Manage Devices</div>

                {!this.state.adding_device&&!this.state.editing_device&&
                <div id="devices-wrapper">
                    <div className="top-button-row">
                        <div className="table-label">Device List</div>
                        <Tooltip placeholder="top" title="Add Device">
                            <div className="add-btn" onClick={this.viewAddDevice} />
                        </Tooltip>
                    </div>

                    <table id="device-table">
                        <thead>
                            <tr>
                                <td className="registered-device-name" style={{fontWeight:"500", textAlign:"left"}}>Name</td>
                                <td className="registered-device-MAC" style={{fontWeight:"500", textAlign:"left"}}>MAC Address</td>
                            </tr>
                        </thead>
                        <tbody>
                            {devices.map(device => 
                            <tr className="registered-device-row" key={device.id}>
                                <td className="registered-device-name">{device.name}</td>
                                <td className="registered-device-MAC">{device.MAC_address}</td>
                                <td className="action-wrapper action-wrapper-2">

                                    <Tooltip placeholder="top" title="Edit Device">
                                        <div className="table-btn edit-btn" onClick={() => this.viewEditDevice(device)} />
                                    </Tooltip>
                                    
                                    <Tooltip placeholder="top" title="Delete Device">
                                        <div className="table-btn delete-btn"                  
                                            onClick={() => {
                                                if (window.confirm("Are you sure you want to delete this device?")){
                                                fetch(`${database}/devices/delete?token=${token}&id=${device.id}`)
                                                .then(this.getDevices)
                                                .catch(err => console.error(err))}
                                            }}
                                        />
                                    </Tooltip>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>

                </div>
                }

                {this.state.adding_device&&
                <div className="add-edit-wrapper">
                    <div className="add-edit-header">
                        <div className="add-edit-header-text">Add Device</div>
                        <div className="disabled-add-btn"/>
                    </div>
                    <div className="add-edit-form">
                        <div className="form-input-label">Device Name</div>
                        <input
                            className="form-control" 
                            onChange={this.logChangeName} 
                            placeholder="Enter Device Name"
                            type='text'
                            name="name"
                            value={this.state.name}
                        />
                        <div className="form-input-label">MAC Address</div>
                        <input 
                            className="form-control" 
                            onChange={this.logChangeMAC}
                            placeholder="Enter MAC Address"
                            type='text'
                            name="MAC_address"
                            value={this.state.MAC_address}
                        />
                        <div className="form-input-label">Site</div>
                        <select 
                            ref="siteSelect" 
                            name="site_select" 
                            onChange={(e) => {this.logSiteId()}} 
                            defaultValue={'DEFAULT'}
                        >
                            <option disabled value="DEFAULT">Select a Site</option>
                            {sites.map(Site => {
                                if (Site.name !== 'All Sites') {
                                    return <option key={Site.id} value={Site.id}>{Site.name}</option>
                                }
                            })}
                        </select>
                        <div className="form-input-label">Assign to Patient</div>
                        <select 
                            ref="patientSelect" 
                            name="patient_select" 
                            className="patient-select" 
                            onChange={(e) => {this.logPatientId()}} 
                            defaultValue={'DEFAULT'}
                        >
                            <option disabled value="DEFAULT">Select Patient</option>
                            {patients.map(Patient => 
                            <option key={Patient.id} value={Patient.id}>{Patient.alias}</option>
                            )}
                            <option value="-1">unassigned</option>
                        </select>   
                        <div className="cs-btn-group">
                            <button className="cs-btn submit-btn" onClick={this.addDevice}>Submit</button>
                            <button className="cs-btn cancel-btn" onClick={this.addEditComplete}>Cancel</button>
                        </div>
                    </div>
                </div>
                }

                {this.state.editing_device&&
                <div className="add-edit-wrapper">
                    <div className="add-edit-header">
                        <div className="add-edit-header-text">Edit Device</div>
                        <div className="disabled-edit-btn"/>
                    </div>
                    <div className="add-edit-form">
                    <div className="form-input-label">Device Name</div>
                        <input
                            className="form-control" 
                            onChange={this.logChangeName} 
                            placeholder={this.state.name}
                            type='text'
                            name="name"
                            value={this.state.name}
                        />
                        <div className="form-input-label">MAC Address</div>
                        <input 
                            className="form-control" 
                            onChange={this.logChangeMAC}
                            placeholder={this.state.MAC_address}
                            type='text'
                            name="MAC_address"
                            value={this.state.MAC_address}
                        />
                        <div className="form-input-label">Site</div>
                        <select 
                            ref="siteSelect" 
                            name="site_select" 
                            onChange={(e) => {this.logSiteId()}} 
                            defaultValue={this.state.site_id}
                        >
                            <option disabled value="DEFAULT">Select a Site</option>
                            {sites.map(Site => {
                                if (Site.name !== 'All Sites') {
                                    return <option key={Site.id} value={Site.id}>{Site.name}</option>
                                }
                            })}
                        </select>
                        <div className="form-input-label">Assign to Patient</div>
                        <select 
                            ref="patientSelect" 
                            name="patient_select" 
                            className="patient-select" 
                            onChange={(e) => {this.logPatientId()}} 
                            defaultValue={this.state.patient_id}
                        >
                            <option disabled value="DEFAULT">Select Patient</option>
                            {patients.map(Patient => 
                            <option key={Patient.id} value={Patient.id}>{Patient.alias}</option>
                            )}
                            <option value="-1">unassigned</option>
                        </select>   
                        <div className="cs-btn-group">
                            <button className="cs-btn submit-btn" onClick={this.editDevice}>Submit</button>
                            <button className="cs-btn cancel-btn" onClick={this.addEditComplete}>Cancel</button>
                        </div>
                    </div>
                </div>
                }

                <Footer/>

            </div>
        );
    }
}

export default Devices;
