import React, { Component } from 'react';
import './LoginHeader.css';
import logo from '../../images/activlink_logo_dark.png';
import back_arrow from '../../images/back_arrow_white_on_blue.png';

class Header extends Component {
    render() {
        return (
            <div id="header-wrapper">
                <div className="header-controls">
                    <div className="header-item">
                        <img id="back-arrow" src={back_arrow} alt="Back" onClick={()=>{window.location.href="/landing"}} />
                    </div>
                    <img id="logo" src={logo} height="40px" width="30px" alt="Activlink" onClick={()=>{window.location.href="/live"}}/>
                    <div className="header-item" />
                </div>
            </div> 
        )
    }
}

export default Header;
