import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import Landing from './pages/Landing';
import Login from './pages/Login';
import Surveys from './pages/Surveys';
import Live from './pages/Live';
import Sites from './pages/Sites';
import Floors from './pages/Floors';
import Zones from './pages/Zones';
import Devices from './pages/Devices';
import Users from './pages/Users';
import Patients from './pages/Patients';
import Notifications from './pages/Notifications';
import Preferences from './pages/Preferences';
import Diagnostics from './pages/Diagnostics';
import Backup from './pages/Backup';
import Calibrations from './pages/Calibrations';

const site_id = sessionStorage.getItem("site_id");
// const floor_id = sessionStorage.getItem("floor_id");

const isLoggedIn = sessionStorage.getItem("isLoggedIn");
const security = sessionStorage.getItem("security");

class Routes extends Component {

    setRoutes() {
        
        if (isLoggedIn === "true") {
            if (security === "0") {
                return(
                    <div>
                        <Route exact path='/' component={Landing}/>
                        <Route exact path='/landing' component={Landing} />
                        <Route exact path='/login' component={Login} />
                        <Route exact path='/live' component={Live}/>
                        <Route exact path='/sites' render={()=>(<Sites site_id={site_id} />)} />
                        <Route exact path='/floors' render={()=>(<Floors site_id={() => {return site_id}} />)} />
                        <Route exact path='/devices' component={Devices}/>
                        <Route exact path='/users' component={Users}/>
                        <Route exact path='/patients' component={Patients}/>
                        <Route exact path='/preferences' component={Preferences}/>
                        <Route exact path='/zones' component={Zones} />
                        <Route exact path='/notifications' component={Notifications} /> 
                        <Route exact path='/preferences' component={Preferences} /> 
                        <Route exact path='/diagnostics' component={Diagnostics} />
                        <Route exact path='/calibrations' component={Calibrations} />
                        <Route exact path='/surveys' component={Surveys} />
                        {process.env.REACT_APP_STATION?<Route exact path='/backup' component={Backup}/>:""}
                    </div>
                )
            }
            else if (security === "3") {
                return(
                    <div>
                        <Route exact path='/' component={Landing}/>
                        <Route exact path='/landing' component={Landing} />
                        <Route exact path='/login' component={Login} />
                        <Route exact path='/live' component={Live}/>
                        <Route exact path='/sites' render={()=>(<Sites site_id={site_id} />)} />
                        <Route exact path='/floors' render={()=>(<Floors site_id={() => {return site_id}} />)} />
                        <Route exact path='/zones' component={Zones} />
                        <Route exact path='/diagnostics' component={Diagnostics} />
                        <Route exact path='/calibrations' component={Calibrations} />
                    </div>
                )
            }
            else {
                return(
                    <div>
                        <Route exact path='/' component={Landing}/>>
                        <Route exact path='/landing' component={Landing} />
                        <Route exact path='/login' component={Login} />
                        <Route exact path='/live' component={Live}/>
                        <Route exact path='/notifications' component={Notifications} />                   
                    </div>
                )
            }
        }
        else {
            return(
                <div>
                    <Route exact path='/' component={Landing}/>
                    <Route exact path='/landing' component={Landing} />
                    <Route exact path='/login' component={Login} />
                </div>
            )
        }
    }
    
    render() {
        if (isLoggedIn === "true") {
            console.log("security::", security);
            if (security === "0") {
                return (
                    <Switch>
                        <Fragment>
                            <Route exact path='/' component={Landing}/>
                            <Route exact path='/landing' component={Landing} />
                            <Route exact path='/login' component={Login} />
                            <Route exact path='/live' component={Live}/>
                            <Route exact path='/sites' render={()=>(<Sites site_id={site_id} />)} />
                            <Route exact path='/floors' render={()=>(<Floors site_id={() => {return site_id}} />)} />
                            <Route exact path='/zones' component={Zones} />
                            <Route exact path='/devices' component={Devices}/> 
                            <Route exact path='/patients' component={Patients}/>
                            <Route exact path='/users' component={Users}/>
                            <Route exact path='/notifications' component={Notifications}/>
                            <Route exact path='/preferences' component={Preferences}/>
                            <Route exact path='/diagnostics' component={Diagnostics} />
                            <Route exact path='/calibrations' component={Calibrations} />
                            <Route exact path='/surveys' component={Surveys} />       
			                {process.env.REACT_APP_STATION?<Route exact path='/backup' component={Backup}/>:""}
                        </Fragment>
                    </Switch>
                )
            }            
            else if (security === "3") {
                return (
                    <Switch>
                        <Fragment>
                            <Route exact path='/' component={Landing}/>
                            <Route exact path='/landing' component={Landing} />
                            <Route exact path='/login' component={Login} />
                            <Route exact path='/live' component={Live}/>
                            <Route exact path='/sites' render={()=>(<Sites site_id={site_id} />)} />
                            <Route exact path='/floors' render={()=>(<Floors site_id={() => {return site_id}} />)} />
                            <Route exact path='/zones' component={Zones} />
                            <Route exact path='/diagnostics' component={Diagnostics} />
                            <Route exact path='/calibrations' component={Calibrations} />
                        </Fragment>
                    </Switch>
                )
            }
            else {
                return (
                    <Switch>
                        <Fragment>
                            <Route exact path='/' component={Landing}/>
                            <Route exact path='/landing' component={Landing} />
                            <Route exact path='/login' component={Login} />
                            <Route exact path='/live' component={Live}/>
                            <Route exact path='/notifications' component={Notifications}/>
                        </Fragment>
                    </Switch>
                )
            }
        }
        else {
            return (
                <Switch>
                    <Fragment>
                        <Route exact path='/' component={Landing}/>
                        <Route exact path='/landing' component={Landing} />
                        <Route exact path='/login' component={Login} />
                    </Fragment>
                </Switch>
            )
        }
    }
}

export default Routes;
