import React, { Component } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import '../common.css';
import './Live.css';

import gray_block from '../../images/gray_block.png';
// eslint-disable-next-line
import {zoomScale, zoomToFit} from './utils.js'

import {setRefreshInterval, clearRefreshInterval} from '../RefreshInterval';
var refreshInterval = null;
var refreshAccessToken = function(value) {token = value};
var token = sessionStorage.getItem("token");

//Import moment.js
var moment = require('moment');
const database = sessionStorage.getItem("database");
const security = sessionStorage.getItem("security");
const user_id = sessionStorage.getItem("user_id");


// let screenWidth = window.screen.width
var MAP_WIDTH = 640;
var MAP_HEIGHT = 480;
if (window.screen.width <= 420) {
    MAP_WIDTH = window.screen.width*.64;
    MAP_HEIGHT = window.screen.width*.48;
}

var MIN_ZOOM = 12;
var MAX_ZOOM = 23;

var mainBlue = "#0096a9";
var safeGreen = "#3dae2b";
var warningOrange = "#f5a800";
var alertRed = "#ca3625";

// Timestamp used for throttling scroll events
var scroll_throttle = new Date();

// Interval function handle for trackPatient
var interval_handle = null;

let image_src = "";

class Live extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tracker:null,
            sites:null,
            site_id:null,
            floors:null,
            floor_idx:-1,
            map_img:gray_block,
            zoom:1,
            width:0,
            height:0,
            direction:0,
            cam_x:0,
            cam_y:0,
            panning:false,
            pan_start_x:0,
            pan_start_y:0,
            show_zones:false, 
            patients:[],
            locate_zone: [],
            localizations: [],
            location_message: null,
            tracked_patient: 9999,
            safety_color: mainBlue,
        };
    }

    componentDidMount() {
        this.getPreferences();
        this.getSites(); 
        this.getLocalizations();
        setRefreshInterval(database, refreshInterval, refreshAccessToken);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        clearRefreshInterval(refreshInterval);
    }

    getSites = () => {
        if (security === "0") {
            // console.log("getting all sites");               
            fetch(`${database}/sites/?token=${token}`)
                .then(response => response.json())
                .then(sites => this.setState({ sites: sites.data }))
                .catch(err => console.error(err));
        }
        else {
            console.log("user id::", user_id);
            fetch(`${database}/users/userId?token=${token}&user_id=${user_id}`)
            .then(response => response.json())
            .then(user => {
                let site_id = user.data[0].site_id;
                fetch(`${database}/sites/site?token=${token}&site_id=${site_id}`)
                .then(response => response.json())
                .then((sites) => {
                    this.setState({ 
                        sites: sites.data,
                     });
                })
                .catch(err => console.error(err));
            })
            .catch(err => console.error(err)); 
        }      
    } 

    getLocalizations = () => {  
        // console.log("getting all localizations")                 ;
        fetch(`${database}/localization/?token=${token}`)
            .then(response => response.json())
            .then(response => {
                this.setState({ localizations: response.data });
                // console.log("localizations:", response.data);
            })
            .catch(err => console.error(err));        
    }


    getPreferences = () => {
        // console.log("Getting Display Time Limit");
        fetch(`${database}/preferences/?token=${token}`)
        .then(response => response.json())
        .then(preferences => this.setState({ allPreferences:preferences.data}, this.getTimeLimit))
        .catch(err => console.error(err));  
    }

    getTimeLimit = () => {
        // console.log("getTimeLimit()");
        const allPreferences = this.state.allPreferences;
        for (let i=0; i<allPreferences.length; i++) {
            // console.log("PREFERENCE:", allPreferences[i].name);
            if (allPreferences[i].name==="lastLocationDisplayTime") {
                let display_time_limit = parseInt(allPreferences[i].value, 10);
                this.setState({
                    display_time_limit:display_time_limit,
                });
            }
        }
    }

    mapMouseDown = (event) => {
        event.preventDefault();
        this.setState({
            pan_start_x:event.pageX,
            pan_start_y:event.pageY,
            panning:true,
        })
    }

    mapMouseMove = (event) => {
        event.preventDefault();
        if (this.state.panning) {
            // Get mouse movement delta's and apply them to camera positions
            let new_x = this.state.cam_x + (event.pageX - this.state.pan_start_x);
            let new_y = this.state.cam_y + (event.pageY - this.state.pan_start_y);
            this.setState({
            cam_x:new_x,
            cam_y:new_y,
            pan_start_x:event.pageX,
            pan_start_y:event.pageY,
            })
        }
    }

    mapMouseWheel = (event) => {
        event.preventDefault();
        let curr_time = new Date();
        // Zoom event throttled to 50 milliseconds
        if (curr_time.getTime() - scroll_throttle.getTime() > 50) {
            scroll_throttle = curr_time;
            let new_zoom = this.state.zoom;
            let new_x = this.state.cam_x;
            let new_y = this.state.cam_y;
            
            // Zoom IN
            if (event.deltaY < 0) {
                new_zoom = Math.min(MAX_ZOOM, new_zoom+0.25);
            }
            // Zoom OUT
            else {
                new_zoom = Math.max(MIN_ZOOM, new_zoom-0.25);
            }

            // Shift Camera positions so that focal point remains the same
            if (new_x !== 0) {
                new_x = new_x*zoomScale(new_x, new_zoom)/zoomScale(new_x, this.state.zoom);
            }
            if (new_y !== 0) {
                new_y = new_y*zoomScale(new_y, new_zoom)/zoomScale(new_y, this.state.zoom);
            }
            
            this.setState({zoom:new_zoom, cam_x:new_x, cam_y:new_y})
        }
    }

    mouseUp = (event) => {
	    this.setState({panning:false});
    }

    // Once a site has been selected load floor data and update state for rendering
    loadFloors = (site_idx) => {
        let site_id = this.state.sites[site_idx].id;
        fetch(`${database}/floors/by_site?token=${token}&site_id=${site_id}`)
        .then( response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then( (floors) => {
            if (floors.data.length>0){                   
                let floors_array = [];
                for (let j=0; j<floors.data.length; j++) {
                    let floor = {
                        id:floors.data[j].id,
                        name:floors.data[j].name,
                        level:floors.data[j].level,
                        floorplan:floors.data[j].floorplan,
                        mime_type:floors.data[j].mime_type,
                        file_size:floors.data[j].file_size,
                        height:floors.data[j].height,
                        width:floors.data[j].width,
                    }
                    floors_array.push(floor);
                }
                // sort floors by level
                floors_array = floors_array.sort(function(a,b){return a.level - b.level});
                this.setState({
                    site_id:this.state.sites[site_idx].id,
                    floors:floors_array,
                    current_floor:floors_array[0],
                    showSelects:false,
                    atTopFloor:false,
                    atBottomFloor:true,
                    tracked_patient:9999,
                    tracked_device:null,
                    safety_color:mainBlue,
                }); 
            }
            else {
                console.log("site", site_id, "has no floors");
            }
        }).catch(function(err) {
            console.log(err);
        }); 

        // get all zones for this site
        this.getZones(site_id);
    }

    // Once a site has been selected load floor data and update state for rendering
    loadFloorDetails = (floor_id) => {
        // console.log("Loading floor details for id::", floor_id);
        let that = this;
        let floors = this.state.floors;
        let currentFloor = {};
        for (let i=0; i < floors.length; i++) {
	        // console.log(floors[i].id)
            if (String(floors[i].id) === String(floor_id)) {
                currentFloor = floors[i];
		        break;
            }
        }

        // Update site details in state
        let zoom = zoomToFit(currentFloor.width, currentFloor.height, MAP_WIDTH, MAP_HEIGHT);
        let width = currentFloor.width;
        let height = currentFloor.height;
        // let floor_id = currentFloor.id;
        image_src = currentFloor.floorplan;
        MIN_ZOOM = Math.round(zoom);
        MAX_ZOOM = Math.round(zoom)+5;

        that.setState({
            map_img:"data:image/bmp;base64,"+image_src,
            zoom:zoom,
            width:width,
            height:height,
            cam_x:0,
            cam_y:0,
            // floor_idx:floor_idx,
            // current_floor:floors[floor_idx],
        });
        // that.getZones(floor_id);
    }

    loadDevices = () => {
	    // console.log("LOAD DEVICES")
        let that = this;
        // retrieve localizations
        let localizationsArray = this.state.localizations;
        let zonesArray = this.state.zones;
        let tempPatients = [];
        // filter localizations where current_zone === a zone in the zones array
        let tempLocations = [];
        for (let i=0; i<localizationsArray.length; i++) {
            for (let j=0; j<zonesArray.length; j++) {
                //  console.log("current zone:", localizationsArray[i].zone_id, "zone id:", zonesArray[j].id);
                if (localizationsArray[i].zone_id === zonesArray[j].id) {
                    tempLocations.push(localizationsArray[i])
                }
            }
        }
        // console.log("Temp Locations", tempLocations);
	    // console.log(tempLocations.length);
        // retrieve devices for filtered localizations
        for (let k=0; k<tempLocations.length; k++) {
            fetch(`${database}/devices/id?token=${token}&device_id=${tempLocations[k].device_id}`)
            .then( response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
		    .then( (device) => {
		        // console.log(JSON.stringify(device))
                // retrieve patient for device, load patients array in state
                // console.log("DEVICE DATA::", device.data[0]);
                let patientID = device.data[0].patient_id;
                fetch(`${database}/patients/patient?token=${token}&id=${patientID}`)
                .then( response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then((patient) => {
                    // console.log("PATIENT DATA::", patient.data[0]);
                    let currentZone = null;
                    for (let l=0; l<tempLocations.length; l++) {
                        if (tempLocations[l].device_id === device.data[0].id) {
                            currentZone = tempLocations[l].zone_id;
                        }
                    }
                    let patient_rec = {
                        name:patient.data[0].alias,
                        device:device.data[0].id,
                        zone:currentZone,
                    }
                    tempPatients.push(patient_rec);
                    that.setState({patients: tempPatients});
                })
                .catch(function(err) {
                    console.log(err);
                });

            })
            .catch(function(err) {
                console.log(err);
            }); 
        }
    }
    
    getZone = (zone_id) => {
        let that = this;
        // get zone by id
        fetch(`${database}/zones/id?token={token}id=${zone_id}`)
            .then(response => response.json())
            .then((zones) => {
                if (zones.data.length > 0) {

                    let polygon = JSON.parse(zones.data[0].polygon);
                    let safety_text = "Alert";
                    let zone_color = "#ca3625";
                    if (zones.data[0].safety_level === "3") {
                        safety_text = "Safe";
                        zone_color = "#3dae2b";
                    }
                    else if (zones.data[0].safety_level === "2") {
                        safety_text = "Warn";
                        zone_color = "#f5a800"
                    }
                    let locate_zone = {
                        id: zones.data[0].id,
                        name: zones.data[0].name,
                        color: zone_color,
                        safety_level: zones.data[0].safety_level,
                        safety_text: safety_text,
                        polygon: polygon,
                        site_id: zones.data[0].site_id,
                        floor_id: zones.data[0].floor_id,
                    }                      

                    that.setState({locate_zone:locate_zone});
                }
                else {
                    console.log("Not able to find device zone");
                    that.setState({locate_zone:null});
                }
            })
            .catch(err => console.error(err));    
    }  
     
    getZones = (site_id) => {
        // console.log("Getting zones for site ID:", site_id)
        let that = this;
        let siteID;
        if (site_id) {
            siteID = site_id;
        }
        else {
            siteID = this.state.current_site.id;
        }
        // get all zones in sql db for this floor
        fetch(`${database}/zones/by_site?token=${token}&site_id=${siteID}`)
            .then(response => response.json())
            .then((zones) => {
                let zones_array = [];
                if (zones.data.length > 0) {
                    for (let i=0; i<zones.data.length; i++) {
                        let polygon = JSON.parse(zones.data[i].polygon);
                        let safety_text = "Alert";
                        if (zones.data[i].safety_level === "3") {
                            safety_text = "Safe";
                        }
                        else if (zones.data[i].safety_level === "2") {
                            safety_text = "Warn";
                        }
                        let zone = {
                            id: zones.data[i].id,
                            name: zones.data[i].name,
                            color: zones.data[i].color,
                            safety_level: zones.data[i].safety_level,
                            safety_text: safety_text,
                            polygon: polygon,
                            site_id: zones.data[i].site_id,
                            floor_id: zones.data[i].floor_id,
                        }
                        zones_array.push(zone);                        
                    }
                    that.setState({zones:zones_array});
                    
                }
                else {
                    console.log("site", site_id, "has no zones");
                    that.setState({zones:[]});
                }
                // console.log("Zones array:", zones_array);
            })
            .then(() => that.loadDevices())
            .catch(err => console.error(err));    
    }

    // This is needed for firefox, otherwise there are 3 distinct events when
    // selecting from a dropdown. This causes race conditions, use this to disable
    // the unneeded events.
    stopEventPropagation = (event) => {
	    event.stopPropagation();
    }

    locateZone = (zone_id) => {
        // console.log("Locating zone:", zone_id);
        let allZones = this.state.zones;
        let tempZones = [];
        // console.log("All Zones:", allZones);
        for (let i=0; i<allZones.length; i++) {
            if (parseInt(allZones[i].id, 10) === parseInt(zone_id, 10)) {
                tempZones.push(allZones[i]);
                this.loadFloorDetails(allZones[i].floor_id);
            }
        }
        this.setState({locate_zone:tempZones});

    }

    isRecordInTimeLimit = (timestamp) => {
        let timeWindow = moment().subtract(1, 'hours');
        let update_timestamp = timestamp;
        let t = update_timestamp.split(/[- :T.]/);
        let update_datetime =  new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
        update_datetime = moment(update_datetime);
        if (update_datetime < timeWindow) {
            return false;
        }
        else {
            return true;
        }
    } 

    trackPatient = (device) => {
        // console.log("trackPatient() device is:", device_id);
        let that = this;
        let location_message = null;
        let zones = this.state.zones;
        let zoneName = null;
        let device_id = parseInt(device, 10);
        let inTimeLimit;
        let safety_color = mainBlue;

        fetch(`${database}/devices/id?token=${token}&device_id=${device_id}`)
        .then( response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then( (device) => {
            this.setState({tracked_patient:device.data[0].id});
        })
        .catch(function(err) {
            console.log(err);
        });

        this.setState({
            tracked_device:device_id,
        });

        // get initial zone
        let initLocalizations = this.state.localizations;
        for (let i=0; i<initLocalizations.length; i++) {
            if (parseInt(initLocalizations[i].device_id, 10) === device_id) {
                // console.log("INITIAL LOCALIZATION:", initLocalizations[i].updated_at);
                this.locateZone(initLocalizations[i].zone_id);
                for (let x=0; x<zones.length; x++) {
                    if (zones[x].id === initLocalizations[i].zone_id) {
                        zoneName = zones[x].name;
                        safety_color = zones[x].color;
                        if (safety_color==="green") {
                            safety_color = safeGreen;
                        } else if (safety_color==="orange") {
                            safety_color = warningOrange;
                        } else if (safety_color==="red") {
                            safety_color = alertRed;
                        }
                    }
                }
                inTimeLimit = this.isRecordInTimeLimit(initLocalizations[i].updated_at);
                if (inTimeLimit) {
                    location_message = "This patient is in zone: " + zoneName;
                }
                else {
                    let hours = parseInt(this.state.display_time_limit/60, 10);
                    let minutes = this.state.display_time_limit % 60;
                    if (hours === 0) {
                        location_message = "Activlink has not detected this patient for more than " + minutes + " minutes.";
                    }
                    else if (hours > 1) {
                        if (minutes > 0) {
                            location_message = "Activlink has not detected this patient for more than " + hours+ " hours " + minutes + "minutes.";
                        }
                        else {
                            location_message = "Activlink has not detected this patient for more than " + hours + " hours.";
                        }
                    }
                    else {
                        if (minutes > 0) {
                            location_message = "Activlink has not detected this patient for more than " + hours + " hour " + minutes + " minutes.";
                        }
                        else {
                            location_message = "Activlink has not detected this patient for more than " + hours + " hour.";
                        }
                    }
                }

                this.setState({ 
                    location_message: location_message,
                    last_location: initLocalizations[i].zone_id,
                    safety_color: safety_color,
                });
            }
            else {
                // console.log("Not the right init device id...", initLocalizations[i].device_id, ",", this.state.tracked_device);
            }
        }
        if (interval_handle) {
            clearInterval(interval_handle);
        }
        // get current zone every five seconds
        interval_handle = setInterval(function(){
            // console.log("TOKEN IS::", token);
            that.getLocalizations();
            let currentLocalizations = that.state.localizations;
            for (let i=0; i<currentLocalizations.length; i++) {
                if (parseInt(currentLocalizations[i].device_id, 10) === parseInt(that.state.tracked_device, 10)) {
                    that.locateZone(currentLocalizations[i].zone_id);

                    inTimeLimit = that.isRecordInTimeLimit(currentLocalizations[i].updated_at);
                    if (inTimeLimit) {
                        if (currentLocalizations[i].zone_id === that.state.last_location) {
                            // patient has not changed zones since last interval
                            location_message = "This patient is in zone: " + zoneName;
                        }
                        else {
                            // patient changed zones
                            for (let x=0; x<zones.length; x++) {
                                if (zones[x].id === currentLocalizations[i].zone_id) {
                                    zoneName = zones[x].name;
                                    safety_color = zones[x].color;
                                    if (safety_color==="green") {
                                        safety_color = safeGreen;
                                    } else if (safety_color==="orange") {
                                        safety_color = warningOrange;
                                    } else if (safety_color==="red") {
                                        safety_color = alertRed;
                                    }
                                }
                            }
                            location_message = "This patient moved to zone: " + zoneName;
                        }
                    }
                    else {
                        // location_message = "Activlink has not detected this patient for more than " + that.state.display_time_limit + " hour(s).";
                        let hours = parseInt(that.state.display_time_limit/60, 10);
                        let minutes = that.state.display_time_limit % 60;
                        if (hours === 0) {
                            location_message = "Activlink has not detected this patient for more than " + minutes + " minutes.";
                        }
                        else if (hours > 1) {
                            if (minutes > 0) {
                                location_message = "Activlink has not detected this patient for more than " + hours+ " hours " + minutes + " minutes.";
                            }
                            else {
                                location_message = "Activlink has not detected this patient for more than " + hours + " hours.";
                            }
                        }
                        else {
                            if (minutes > 0) {
                                location_message = "Activlink has not detected this patient for more than " + hours + " hour " + minutes + " minutes.";
                            }
                            else {
                                location_message = "Activlink has not detected this patient for more than " + hours + " hour.";
                            }
                        }
                    }

                    that.setState({
                        location_message:location_message,
                        last_location:currentLocalizations[i].zone_id,
                        safety_color: safety_color,
                    });
                } 
            }
        }, 5000);

    }

    render() {
        let width = zoomScale(this.state.width, this.state.zoom);
        let height = zoomScale(this.state.height, this.state.zoom);
        let tracker = this.state.tracker;
        let zoom = this.state.zoom;
        let cam_x = this.state.cam_x;
        let cam_y = this.state.cam_y;

        return (
            <div id="live-container" onMouseUp={this.mouseUp}>

                <Header />

                <div className="page-title">Live View</div>

                <div className="live-wrapper">

                    <div id="live-localization-controls">


                        <div className="live-localization-selects">
                            <div className="live-localization-labels">
                                <div id="live-site-label" className="live-localization-label">Site</div>
                            </div>
                            <select 
                                id="live-site-select" 
                                className="live-select" 
                                onChange={(event) =>{this.loadFloors(event.target.value)}} 
                                defaultValue={9999}
                            >
                                <option 
                                    className="disabled"
                                    value={9999} 
                                    disabled
                                >Select a Site</option>
                                {this.state.sites && this.state.sites.map((value, index) => {
                                    if (value.name !== "All Sites") {
                                        return (
                                            <option value={index} style={{color:"black"}} key={index}>{value.name}</option>
                                        )
                                    }
                                })}
                            </select>
                        </div>

                        <div className="live-localization-selects">
                            <div className="live-localization-labels">
                                <div id="live-patient-label" className="live-localization-label">Patient</div>
                            </div>
                            <select 
                                id="live-patient-select" 
                                className="live-select" 
                                onChange={(event) =>{this.trackPatient(event.target.value)}}                            
                                value={this.state.tracked_patient}
                            >
                                <option 
                                    className="disabled"
                                    value={9999} 
                                    disabled
                                >Select a Patient</option>
                                {this.state.patients && this.state.patients.map((value, index) => {
                                    return (
                                    <option value={value.device} name={value.name} style={{color:"black"}} key={index}>{value.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>

                    <div id="map-wrapper" style={{backgroundColor: this.state.safety_color}}>
                        <div 
                            id="location-message"
                            style={{
                                display:this.state.site_id&&this.state.tracked_device?"block":"none",
                                
                            }}
                        >
                            <div className="location-text" style={{backgroundColor: this.state.safety_color}}>{this.state.location_message}</div>
                        </div>
                        <div 
                            id="map" 
                            style={{
                                position:"relative", 
                                margin:"10px auto 0 auto", 
                                overflow:"hidden", 
                                background:"#FFFFFF", 
                                maxWidth:"640px",
                                maxHeight:"480px",
                                display:this.state.site_id&&this.state.tracked_device?"block":"none"
                            }}>
                            <img 
                                style={{position:"absolute", transform:'rotate('+this.state.direction+"deg)", left:MAP_WIDTH/2-width/2 + cam_x, top:MAP_HEIGHT/2-height/2 + cam_y}} id="map" width={width} height={height}
                                src={"data:image/bmp;base64,"+image_src} 
                                alt="" 
                            />
                            <svg 
                                onMouseDown={this.mapMouseDown} 
                                onMouseMove={this.mapMouseMove} 
                                onWheel={this.mapMouseWheel} 
                                style={{position:"absolute", width:MAP_WIDTH, height:MAP_HEIGHT, left:0, top:0}}
                            >
                                <g id="map-bounds" 
                                    transform={"translate("+(MAP_WIDTH/2-width/2+cam_x)+","+(MAP_HEIGHT/2+height/2+cam_y)+")"}
                                    style={{display:this.state.show_zones?"block":"none"}}
                                >
                                    {this.state.zones && this.state.zones.map((zone, index) => {
                                    let points = "";
                                    var idx;
                                    for (idx in (zone.polygon)) {
                                        let point = zone.polygon[idx];
                                        points += (zoomScale(point.x, zoom)) + "," + (-1*zoomScale(point.y, zoom)) + " "
                                    }
                                    return (
                                        <g className="zone-bounds" key={zone.id}>
                                            <polygon points={points} style={{stroke:zone.color, fill:zone.color, opacity:.3}}/>
                                            <polygon points={points} style={{stroke:zone.color,strokeWidth:2, strokeOpacity:1, fill:'none'}}/>
                                                {index === this.state.zone_selected && zone.polygon.map((point,index) => {
                                                let cx = zoomScale(point.x, zoom);
                                                let cy = -1*zoomScale(point.y, zoom);
                                                let stroke = index===this.state.zone_point_selected?"black":"none";
                                                return (
                                                    <circle onMouseDown={()=>{this.setState({zone_point_selected:index, moving_point:true})}}
                                                        cx={cx} cy={cy}
                                                        r="5"
                                                        style={{fill:zone.color, stroke:stroke, strokeWidth:"3px" }}
                                                    />
                                                )
                                                })}
                                        </g>
                                    )
                                    })}
                                </g>

                                <g id="locate-zone" 
                                    transform={"translate("+(MAP_WIDTH/2-width/2+cam_x)+","+(MAP_HEIGHT/2+height/2+cam_y)+")"}
                                    style={{display:this.state.locate_zone?"block":"none"}}
                                >
                                    {this.state.locate_zone && this.state.locate_zone.map((zone, index) => {
                                    let points = "";
                                    var idx;
                                    for (idx in (zone.polygon)) {
                                        let point = zone.polygon[idx];
                                        points += (zoomScale(point.x, zoom)) + "," + (-1*zoomScale(point.y, zoom)) + " ";
                                    }
                                    return (
                                    <g className="zone-bounds" key={zone.id}>
                                        <polygon points={points} style={{stroke:zone.color, fill:zone.color, opacity:.3}}/>
                                        <polygon points={points} style={{stroke:zone.color,strokeWidth:2, strokeOpacity:1, fill:'none'}}/>
                                        {index === this.state.zone_selected && zone.polygon.map((point,index) => {
                                            let cx = zoomScale(point.x, zoom);
                                            let cy = -1*zoomScale(point.y, zoom);
                                            let stroke = index===this.state.zone_point_selected?"black":"none";
                                            return (
                                                <circle onMouseDown={()=>{this.setState({zone_point_selected:index, moving_point:true})}}
                                                    cx={cx} cy={cy}
                                                    r="5"
                                                    style={{fill:zone.color, stroke:stroke, strokeWidth:"3px" }}
                                                />
                                            )
                                        })}
                                    </g>
                                    )
                                    })}
                                </g>

                                {tracker && tracker.map((value, index) => {
                                    // Local coordinates start from bottom left of floorplan
                                    let local_coords = value.properties.local_coordinates;
                                    let x = MAP_WIDTH/2-width/2 + zoomScale(local_coords[0], zoom) + cam_x;
                                    let y = MAP_HEIGHT/2+height/2 - zoomScale(local_coords[1], zoom) + cam_y;
                                    return(
                                        <circle r="5" cx={x-5} cy={y-5} fill="red"/>
                                    )
                                })}
                            </svg>
                        </div>
                    </div>
                    
                </div>

                <Footer />
            </div>
        );
    }
}

export default Live;
